import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Admin/Header";
import { useTheme } from "@mui/material";
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';

const Services = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [services, setServices] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_ADMIN_URL}/services`, { withCredentials: true });
        setServices(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des services:', error);
      }
    };

    fetchServices();
  }, []);

  const handleAddService = () => {
    navigate('/admin/services/new');
  };

  const columns = [    
    { field: "id", headerName: "ID", width: 70 },    
    { field: "title", headerName: "Titre", width: 150 },    
    { field: "sub_description", headerName: "Sous-description", width: 350 },    
    { field: "description", headerName: "Description", width: 500 },    
    { field: "keywords", headerName: "Mots-clés", width: 200 },
    {
        field: "actions",
        headerName: "Actions",
        sortable: false,
        width: 70,
        renderCell: (params) => {
          const handleEdit = () => {
            navigate(`/admin/services/${params.id}`);
            console.log('Modifier le service avec id:', params.id);
          };
    
          const handleDelete = async () => {
            try {
              await axios.delete(`${process.env.REACT_APP_BACKEND_ADMIN_URL}/services/${params.id}`, { withCredentials: true });
              setServices(services.filter((service) => service.id !== params.id));
              // Ajoutez ici une notification de succès ou un message similaire si nécessaire
            } catch (error) {
              console.error('Erreur lors de la suppression de la demande:', error);
              // Ajoutez ici une notification d'erreur ou un message similaire si nécessaire
            }
          };
    
          return (
            <>
              <EditIcon onClick={handleEdit} style={{ cursor: 'pointer', marginRight: '10px' }} />
              <DeleteIcon onClick={handleDelete} style={{ cursor: 'pointer' }} />
            </>
          );
        }
      }
  ];
  
  return (
    <Box m="20px">
      <Header
        title="Services"
        subtitle="Liste des services proposé par l'association"
      />
      <Box mb="20px">
        <Button
        variant="contained"
        color="secondary"
        onClick={handleAddService}
        >
        Ajouter Service
        </Button>
      </Box>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .no-border-bottom": {
            borderBottom: "none !important",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={services}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5, 10, 20]}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

export default Services;
