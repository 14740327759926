import React, { useState, useEffect } from 'react';
import { Box, useTheme, Link, IconButton  } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Admin/Header";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

const AdminDemande = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [demandes, setDemandes] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDemandes = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_ADMIN_URL}/demandes`);
        setDemandes(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des paiements:', error);
      }
    };

    fetchDemandes();
  }, []);

  const handleDelete = async (demandeId) => {
    try {
      await axios.delete(`${process.env.REACT_APP_BACKEND_ADMIN_URL}/demandes/${demandeId}`, { withCredentials: true });
      setDemandes(demandes.filter((demande) => demande.id !== demandeId));
      // Ajoutez ici une notification de succès ou un message similaire si nécessaire
    } catch (error) {
      console.error('Erreur lors de la suppression de la demande:', error);
      // Ajoutez ici une notification d'erreur ou un message similaire si nécessaire
    }
  };

  const columns = [
    { field: "id", headerName: "ID" },
    { field: "user_id", headerName: "User ID", flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },
    { field: "type_demande", headerName: "Type de la demande", flex: 1 },
    { field: "titre", headerName: "Titre", flex: 1 },
    { field: "created_at", headerName: "Crée le", flex: 1 },
    { 
      field: "conversation_id", 
      headerName: "Conversation", 
      flex: 1,
      renderCell: (params) => (
        <Link 
          href="#" 
          onClick={(e) => {
            e.preventDefault();
            navigate(`/chat/${params.row.conversation_id}/${params.row.id}`);
          }} 
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          {params.value}
        </Link>
      )
    },
    { field: "statut", headerName: "Statut", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      renderCell: (params) => (
        <IconButton onClick={() => handleDelete(params.row.id)}>
          <DeleteOutlinedIcon />
        </IconButton>
      ),
    },
    // Autres colonnes si nécessaire
  ];

  const adaptedDemandes = demandes.map(demande => ({
    id: demande.id,
    user_id: demande.user_id,
    email: demande.email,
    type_demande: demande.type_demande,
    titre: demande.titre,
    created_at: new Date(demande.created_at).toLocaleDateString(),
    conversation_id: demande.conversation_id,
    statut: demande.statut
  }));

  return (
    <Box m="20px">
      <Header title="Demandes" subtitle="Liste des Demandes" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-row": {
            borderBottom: "none",
          },
        }}
      >
        <DataGrid checkboxSelection rows={adaptedDemandes} columns={columns} />
      </Box>
    </Box>
  );
};

export default AdminDemande;
