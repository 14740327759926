import React from 'react';
import { useLocation } from 'react-router-dom';
import Header from './Header';

const HeaderOrTopbar = () => {
    const location = useLocation();
    const isAdminRoute = location.pathname.startsWith('/admin');

    return isAdminRoute ? null : <Header />;
}

export default HeaderOrTopbar;
