import React, { useContext } from 'react';
import UserContext from './Others/UserContext';
import '../styles/PrivateAdherentRoute.css';
import adptInscription from '../assets/animations/GIF-ADPT-INSCRIPTION.gif';
import adptAdherer from '../assets/animations/GIF-ADPT-ADHERER.gif';
import adptDemande from '../assets/animations/GIF-ADPT-DEMANDE.gif';
import Footer from './Footer/Footer';

const PrivateAdherentRoute = ({ children }) => {
  const { userRole, isLoading } = useContext(UserContext);

  const Step = ({ align, title, gifSrc, explanation, linkUrl, linkText, additionalText }) => (
    <div className={`step ${align}`}>
      <h2>{title}</h2>
      <div className="step-content">
        <img src={gifSrc} alt={title} className="step-gif" />
        <div className="step-text">
          <p>{explanation}</p>
          <a href={linkUrl}>{linkText}</a>
          <p className="text-informatif-sombre">{additionalText}</p>
        </div>
      </div>
    </div>
  );

  if (isLoading) {
    return <div className="privateRouteContainer"><div className="loadingMessage">Chargement...</div></div>;
  }

  if (userRole === 2 || userRole === 4) {
    return children;
  } else {
    return (
      <div>
        <div className="privateRouteContainer">
          <h1 className="main-title">Cette page est disponible pour les adhérents uniquement</h1>
          <Step
            align="left-aligned"
            title="Étape 1: S'inscrire"
            gifSrc={adptInscription}
            explanation="Pour s'inscrire, rien de plus simple cliquez sur le lien ci-dessous et remplissez vos informations. "
            linkUrl="inscription"
            linkText="Inscription"
            additionalText=""
          />
          {/* Répéter pour chaque étape, en alternant les alignements 'left-aligned' et 'right-aligned' */}
          <Step
            align="right-aligned"
            title="Étape 2: Adhérer"
            gifSrc={adptAdherer}
            explanation="Une fois que vous êtes inscrit et connecté, rendez vous sur la page 'Adhérer' et abonnez-vous. Le coût de l'adhésion à l'association est de 50€ par an. Ce prix permet à l'association de vivre et de développer afin de pouvoir vous apportez notre aide."
            linkUrl="adherer"
            linkText="Adhérez-ici"
            additionalText=""
          />
          <Step
            align="left-aligned"
            title="Étape 3: Faire votre demande"
            gifSrc={adptDemande}
            explanation="Si vous avez adhérer à l'association, vous pouvez faire des demandes auprès de l'association afin d'obtenir de l'aide dans vos démarches administratives ou obtenir des conseils. "
            linkUrl="demande"
            linkText="Faites votre demande ici"
            additionalText=""
          />
        </div>

        <Footer />
      </div>
    );
  }
};

export default PrivateAdherentRoute;