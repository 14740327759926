import React from 'react';
import { Link } from 'react-router-dom';  // Importez le composant Link pour la redirection
import '../../styles/PasswordResetConfirmation.css';
import { Player } from '@lottiefiles/react-lottie-player';
import Footer from '../Footer/Footer.js';

function PasswordResetConfirmation() {
    return (
        <div>
            <div className="confirmation-container">
                <h2>Mot de passe réinitialisé avec succès !</h2>
                <p>Vous pouvez vous <Link to="/connexion">connecter ici</Link>.</p>
                <Player
                    autoplay
                    loop
                    src="https://lottie.host/173a353a-7b67-487e-8947-ef96815f582b/mZLIF84gGT.json"
                    style={{ height: '200px', width: '200px' }}
                />
            </div>

            <Footer /> 
        </div>
        
    );
}

export default PasswordResetConfirmation;
