import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../../styles/AllNews.css';
import Pnews from '../../assets/images/actualité.png';
import arrow from '../../assets/images/arrow.png';
import Footer from '../Footer/Footer';

function formatDate(dateString) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('fr-FR', options);
}

const AllNews = () => {
    const [articles, setArticles] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchArticles = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/articles/articles`, { withCredentials: true });
                setArticles(response.data);
            } catch (error) {
                console.error('Erreur lors du chargement des articles:', error);
            }
        };
        fetchArticles();
    }, []);

    // Fonction pour trier et organiser les articles par année et mois
    const sortedArticles = () => {
        const sorted = {};
        articles.forEach(article => {
            const year = new Date(article.date).getFullYear();
            const month = new Date(article.date).toLocaleString('fr-FR', { month: 'long' });
            const key = `${month} ${year}`;

            if (!sorted[key]) {
                sorted[key] = [];
            }
            sorted[key].push(article);
        });
        return sorted;
    };

    const handleArticleClick = (articleTitle) => {
        // Remplacer les espaces par des tirets et encoder le titre
        const urlSafeTitle = encodeURIComponent(articleTitle.toLowerCase().replace(/\s+/g, '-'));
        navigate(`/actualite/${urlSafeTitle}`);
    };

    return (
        <div>
            <div className="all-news-container">
                <h1 className="all-news-title">Toutes les Actualités</h1>
                {Object.entries(sortedArticles()).map(([dateKey, articlesInMonth]) => (
                    <div key={dateKey} className="news-month">
                        <h2 className="month-year">{dateKey}</h2>
                        <hr className="month-divider" />
                        <div className="news-articles">
                            {articlesInMonth.map(article => (
                                <div key={article.id} className="news-article" onClick={() => handleArticleClick(article.titre)}>
                                    <img crossOrigin="anonymous" src={article.image_path ? `${process.env.REACT_APP_BACKEND_URL}/${article.image_path}` : Pnews} 
                                    alt={article.titre}  className='photo-news'/>
                                    <h3>{article.titre}</h3>
                                    <p>{article.sub_description}</p>
                                    <p className="article-date">{formatDate(article.date)}</p>
                                    <img src={arrow} alt="Voir plus" className="arrow-icon desktop-only" />
                                    <button className="btn-more mobile-only">Voir plus</button>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>

            <Footer />
        </div>
        
    );
};

export default AllNews;
