import React from 'react';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../styles/LoginPage.css';
import Faq from '../Faq/Faq.js';
import Footer from '../Footer/Footer.js';
import UserContext from '../Others/UserContext';
import { Link } from 'react-router-dom';

function LoginPage() {
    const { setIsUserLoggedIn, setUserRole, setUserId } = useContext(UserContext);
    const navigate = useNavigate();
    

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const email = document.getElementById('username').value;
        const password = document.getElementById('password').value;
        
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users/login`, {
                method: "POST",
                credentials: 'include',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ email, password })
            });
    
            const jsonData = await response.json();

            if (response.status !== 401) {
                setIsUserLoggedIn(true);
                setUserRole(jsonData.role);
                setUserId(jsonData.userId);
                navigate("/");
            } else {
                // Gérer l'erreur d'authentification
                alert(jsonData);
            }
        } catch (err) {
            console.error(err.message);
        }
    }

    return (
        <div>
            <div className="login-container">
                <div className="login-box-loginPage">
                    <h2>Déjà membre ?</h2>
                    <form className="login-form" onSubmit={handleSubmit}>
                        <div className="input-field">
                            <label htmlFor="username">Email:</label>
                            <input type="text" id="username" required />
                        </div>
                        <div className="input-field">
                            <label htmlFor="password">Mot de passe:</label>
                            <input type="password" id="password" required />
                        </div>
                        <div className="forgot-password">
                            <Link to="/recovery">Mot de passe oublié ?</Link>
                        </div>
                        <button type="submit" className="login-button">Se connecter</button>
                    </form>
                </div>
                <div className="register-box-loginPage">
                    <h3>Nouveau membre ?</h3>
                    <Link to="/inscription" className="register-page">Crée un compte</Link>
                </div>
            </div>

            <Faq />
            <Footer /> 
        </div>
    );
}

export default LoginPage;