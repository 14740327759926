import React, { useState, useEffect } from 'react';
import { Box, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Admin/Header";
import axios from 'axios';

const AdminPayment = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [paiements, setPaiements] = useState([]);

  useEffect(() => {
    const fetchPaiements = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_ADMIN_URL}/paiements`);
        setPaiements(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des paiements:', error);
      }
    };

    fetchPaiements();
  }, []);

  const columns = [
    { field: "id", headerName: "ID" },
    { field: "user_id", headerName: "User ID", flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },
    { field: "montant", headerName: "Montant", flex: 1 },
    { field: "date_paiement", headerName: "Date de Paiement", flex: 1 },
    { field: "stripe_payment_id", headerName: "Stripe Payment ID", flex: 1 },
    { field: "description", headerName: "Description", flex: 1 },
    // Autres colonnes si nécessaire
  ];

  const adaptedPaiements = paiements.map(paiement => ({
    id: paiement.id,
    user_id: paiement.user_id,
    email: paiement.email,
    montant: `${paiement.montant}€`,
    date_paiement: new Date(paiement.date_paiement).toLocaleDateString(),
    stripe_payment_id: paiement.stripe_payment_id,
    description: paiement.description
  }));

  return (
    <Box m="20px">
      <Header title="Paiement" subtitle="Liste des Paiements" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-row": {
            borderBottom: "none",
          },
        }}
      >
        <DataGrid checkboxSelection rows={adaptedPaiements} columns={columns} />
      </Box>
    </Box>
  );
};

export default AdminPayment;
