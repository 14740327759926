import React from 'react';
import '../../styles/ConfirmationReset.css';
import { Player } from '@lottiefiles/react-lottie-player';
import Footer from '../Footer/Footer.js';

function ConfirmationReset() {
    return (
        <div>
            <div className="confirmation-container">
                <h2>Email envoyé !</h2>
                <p>Veuillez consulter votre boîte de réception pour les instructions de récupération.</p>
                <Player
                    autoplay
                    loop
                    src="https://lottie.host/3f5ae1bc-d7c0-4250-a783-b6ea5b18885c/ZBY0uldhAP.json"
                    style={{ height: '200px', width: '200px' }}
                />
            </div>

            <Footer />
        </div>
        
    );
}

export default ConfirmationReset;
