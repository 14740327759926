import React, { useState, useEffect, useContext, Suspense } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import '../../styles/MyAccount.css';
import Footer from '../Footer/Footer.js';
import UserContext from '../Others/UserContext';
const DemandesListe = React.lazy(() => import('../DemandesListe/DemandesListe'));

function MyAccount() {
    const [ongletActif, setOngletActif] = useState('informationsPersonnelles');
    const [demandes, setDemandes] = useState([]);
    const [prenom, setPrenom] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const { setIsUserLoggedIn } = useContext(UserContext);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/auth/user`, { withCredentials: true })
             .then(response => {
                 const user = response.data;
                 setPrenom(user.firstname); 
             })
             .catch(error => {
                 console.error("Erreur lors de la récupération des données:", error);
             });
    }, []);

    useEffect(() => {
        const chargerDemandes = async () => {
          try {
            axios.get(`${process.env.REACT_APP_BACKEND_URL}/demandes/demandes`, { withCredentials: true })
            .then(response => {
                console.log("Demandes récupérées:", response.data); // Ajoutez cette ligne pour le débogage
                setDemandes(response.data);
            })
          } catch (error) {
            console.error('Erreur lors du chargement des demandes:', error);
          }
        };
    
        if (ongletActif === 'mesDemandes') {
          chargerDemandes();
        }
      }, [ongletActif]);

    useEffect(() => {
        if (location.state && location.state.from === 'demande') {
          setOngletActif(location.state.tab);
        }
      }, [location]);

    const handleLogout = () => {
        // Effectuer une requête de déconnexion
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/logout`, {}, { withCredentials: true })
            .then(response => {
                setIsUserLoggedIn(false); 
                navigate('/');
            })
            .catch(error => {
                console.error("Erreur lors de la déconnexion:", error);
            });
    }

    return (
        <div>
            <div className="compte-wrapper">
                <div className='mon-compte-container onglets-container'>
                    <div className="salutation">
                        Bonjour {prenom}
                    </div>
                    <div className="panneau-onglets">
                    <button className={ongletActif === 'informationsPersonnelles' ? 'actif' : ''} onClick={() => setOngletActif('informationsPersonnelles')}>
                        Informations personnelles
                    </button>
                        <button onClick={() => setOngletActif('mesDemandes')}>Mes demandes</button>
                        {/* <button onClick={() => setOngletActif('facturation')}>Facturation</button> */}
                        <button onClick={() => setOngletActif('mesDocuments')}>Mes documents</button>
                        <button onClick={handleLogout}>Déconnexion</button>
                    </div>
                </div>

                <div className='mon-compte-container'>
                    <div className="contenu-onglet">
                        {ongletActif === 'informationsPersonnelles' && <InformationsPersonnelles />}
                        {ongletActif === 'mesDemandes' && (
                        <Suspense fallback={<div>Chargement des demandes...</div>}>
                            <DemandesListe demandes={demandes} />
                        </Suspense>
                        )}
                    </div>
                </div>
            </div>
            
            

            <Footer />
        </div>
        
    );
}

function InformationsPersonnelles() {
    const [userData, setUserData] = useState({
        lastname: "",
        phone: "",
        firstname: "",
        civilite: "",
        date_naissance: ""
    });
    const [initialData, setInitialData] = useState({
        lastname: "",
        phone: "",
        firstname: "",
        civilite: "",
        date_naissance: ""
    });

    const [showUpdateBanner, setShowUpdateBanner] = useState(false);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/auth/user`, { withCredentials: true })
             .then(response => {
                const serverData = { ...response.data };

                if (serverData.date_naissance) {
                    serverData.date_naissance = toLocalISODateString(serverData.date_naissance);
                }
                setUserData(serverData);
                setInitialData(serverData);
             })
             .catch(error => {
                 console.error("Erreur lors de la récupération des données:", error);
             });
    }, []);// Le tableau vide signifie que cet effet s'exécutera une fois au chargement du composant

    const hasDataChanged = () => {
        return JSON.stringify(userData) !== JSON.stringify(initialData);
    } 

    const handleFormSubmit = (e) => {
        e.preventDefault();

        const dataToUpdate = {
            ...userData,
            date_naissance: toLocalISODateString(userData.date_naissance)
        };
    
        axios.put(`${process.env.REACT_APP_BACKEND_URL}/users/${userData.id}`, dataToUpdate, { withCredentials: true })
            .then(response => {
                const updatedData = response.data;
                if (updatedData.date_naissance) {
                    updatedData.date_naissance = toLocalISODateString(updatedData.date_naissance);
                }
                setInitialData(updatedData);
                setUserData(updatedData);  // Assurez-vous de mettre à jour userData également
                setShowUpdateBanner(true);

                // Masquer le bandeau après 3 secondes
                setTimeout(() => {
                    setShowUpdateBanner(false);
                }, 2000);
            })
            .catch(error => {
                console.error("Erreur lors de la mise à jour des données:", error);
            });
    }

    return (
        <div>
            <h1>Mon compte</h1>
            {showUpdateBanner && <div className="update-banner">Mise à jour des données</div>}
            <h2>Informations personnelles</h2>
            <form className="my-account-form" onSubmit={handleFormSubmit}>
                <div className="civility-container">
                    <label>Civilité:</label>
                    <div>
                        <input 
                            type="radio" 
                            id="homme" 
                            name="civilite" 
                            value="Homme"
                            checked={userData.civilite === "Homme"} 
                            onChange={(e) => setUserData({ ...userData, civilite: e.target.value })}
                        />
                        <label className="radio-label" htmlFor="homme">Homme</label>

                        <input 
                            type="radio" 
                            id="femme" 
                            name="civilite" 
                            value="Femme"
                            checked={userData.civilite === "Femme"} 
                            onChange={(e) => setUserData({ ...userData, civilite: e.target.value })}
                        />
                        <label className="radio-label" htmlFor="femme">Femme</label>

                        <input 
                            type="radio" 
                            id="autre" 
                            name="civilite" 
                            value="Autre"
                            checked={userData.civilite === "Autre"} 
                            onChange={(e) => setUserData({ ...userData, civilite: e.target.value })}
                        />
                        <label className="radio-label" htmlFor="autre">Autre</label>

                        <input 
                            type="radio" 
                            id="nonPrecise" 
                            name="civilite" 
                            value="NonPrecise"
                            checked={userData.civilite === "NonPrecise"} 
                            onChange={(e) => setUserData({ ...userData, civilite: e.target.value })}
                        />
                        <label className="radio-label" htmlFor="nonPrecise">Non précisé</label>
                    </div>
                </div>
                <div className="form-row">
                    <div>
                        <label>Nom:</label>
                        <input type="text" value={userData.lastname} onChange={(e) => setUserData({ ...userData, lastname: e.target.value })} />
                    </div>
                    <div>
                        <label>Date de naissance:</label>
                        <input 
                            type="date" 
                            value={userData.date_naissance || ''} 
                            onChange={(e) => setUserData({ ...userData, date_naissance: e.target.value })} 
                        />
                    </div>
                </div>
                <div className="form-row">
                    <div>
                        <label>Prénom:</label>
                        <input type="text" value={userData.firstname} onChange={(e) => setUserData({ ...userData, firstname: e.target.value })} />
                    </div>
                    <div>
                        <label>Téléphone:</label>
                        <input type="tel" value={userData.phone} onChange={(e) => setUserData({ ...userData, phone: e.target.value })} />
                    </div>
                </div>

                <button type="submit" disabled={!hasDataChanged()}>Valider</button>
            </form>
        </div>
        
    );
}

function toLocalISODateString(dateString) {
    const date = new Date(dateString);
    const offset = date.getTimezoneOffset();
    const adjustedDate = new Date(date.getTime() - offset * 60 * 1000);
    return adjustedDate.toISOString().split('T')[0];
}

export default MyAccount;