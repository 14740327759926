import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';

function InscriptionConfirmationPage() {
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        // Empêcher l'exécution si l'activation a déjà été tentée

        const queryParams = new URLSearchParams(location.search);
        const token = queryParams.get('token');

        const activateAccount = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/users/activation?token=${token}`);
                if (response.data.success) {
                    setTimeout(() => navigate('/confirmation-inscription'));
                } else {
                    setMessage(response.data.message || 'Impossible d\'activer le compte. Veuillez réessayer ou contacter le support.');
                }
            } catch (error) {
                setMessage(error.response ? error.response.data.message : 'Une erreur est survenue. Veuillez réessayer plus tard.');
            } finally {
                setLoading(false);
            }
        };

        activateAccount();
    }, [navigate, location]); // Ajouter isActivated dans les dépendances

    return (
        <div>
            {loading ? <p>Activation en cours...</p> : <p>{message}</p>}
        </div>
    );
}

export default InscriptionConfirmationPage;
