import React, { useEffect, useState } from 'react';
import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Admin/Header";
import { useTheme } from "@mui/material";
import axios from 'axios';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const Users = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    const fetchResources = async () => {
      try {
        const [usersResponse, rolesResponse] = await Promise.all([
          axios.get(`${process.env.REACT_APP_BACKEND_ADMIN_URL}/users`, { withCredentials: true }),
          axios.get(`${process.env.REACT_APP_BACKEND_ADMIN_URL}/roles`, { withCredentials: true })
        ]);
        setUsers(usersResponse.data);
        setRoles(rolesResponse.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des données:', error);
      }
    };

    fetchResources();
  }, []);

  const updateUser = async (userId, updates) => {
    try {
      // Construire dynamiquement l'objet de mise à jour en incluant seulement les propriétés fournies
      const updateData = {};
      if (updates.role_id !== undefined) {
        updateData.role_id = updates.role_id;
      }
      if (updates.isactive !== undefined) {
        // Convertir la valeur en booléen
        updateData.isactive = updates.isactive === "true";
      }
  
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_ADMIN_URL}/users/${userId}`,
        updateData,
        { withCredentials: true }
      );
  
      // Mise à jour de l'état local des utilisateurs pour refléter les changements immédiatement dans l'interface utilisateur
      console.log("Mise à jour réussie:", response.data);
      setUsers(users.map(user => user.id === userId ? { ...user, ...updateData } : user));
    } catch (error) {
      console.error("Erreur lors de la mise à jour de l'utilisateur:", error);
    }
  };

  const columns = [    
    { field: "id", headerName: "ID", flex: 0.5 },    
    { field: "firstname", headerName: "Prénom" },    
    { field: "lastname", headerName: "Nom", flex: 1 },    
    { field: "email", headerName: "Email", flex: 1 },    
    { field: "phone", headerName: "Téléphone", flex: 1 }, 
    { field: "civilite", headerName: "Civilité", flex: 1 },
    {
      field: "nom_role",
      headerName: "Rôles",
      flex: 1,
      renderCell: (params) => (
        <Select
            value={params.row.role_id || ''}
            onChange={(e) => updateUser(params.id, { role_id: e.target.value })}
            displayEmpty
        >
            {roles.map((role) => (
                <MenuItem key={role.id} value={role.id}>
                    {role.nom_role}
                </MenuItem>
            ))}
        </Select>
      ),
      valueGetter: (params) => {
        const role = roles.find((r) => r.id === params.row.role_id);
        return role ? role.nom_role : 'Rôle non trouvé';
      }
    },
    { field: "created_at", headerName: "Date de création", flex: 1 },
    {
      field: "isactive",
      headerName: "Compte activé",
      flex: 1,
      renderCell: (params) => (
        <Select
          value={params.row.isactive.toString()}
          onChange={(e) => updateUser(params.id, { isactive: e.target.value })}
          displayEmpty
        >
          <MenuItem value="true">Activé</MenuItem>
          <MenuItem value="false">Désactivé</MenuItem>
        </Select>
      ),
    }
  ];
  
  return (
    <Box m="20px">
      <Header
        title="Utilisateurs"
        subtitle="Listes des utilisateurs"
      />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .no-border-bottom": {
            borderBottom: "none !important",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={users}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

export default Users;
