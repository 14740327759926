import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
import './Contact.css'; 
import Faq from '../Faq/Faq.js';
import Footer from '../Footer/Footer.js';

function Contact() {
    const [state, handleSubmit] = useForm("xpzgkver");
    
    if (state.succeeded) {
        return <p>Merci de nous avoir contacté !</p>;
    }

    return (
        <div>
            <section className="contact-section">
                <div className="contact-container">
                    <h1 className="contact-title">Contactez-nous</h1>
                    <p className="contact-description">Nous sommes toujours ravis d'entendre vos retours, questions ou suggestions. N'hésitez pas à nous contacter via le formulaire ci-dessous.</p>
                    
                    <form className="contact-form" onSubmit={handleSubmit}>
                        <label htmlFor="email">
                            Votre adresse mail
                            <input type="email" name="email" placeholder="Adresse e-mail" required />
                        </label>
                        <ValidationError prefix="Email" field="email" errors={state.errors} />

                        <label>
                            Sujet
                            <select name="subject" required defaultValue="">
                                <option value="" disabled>Choisir un sujet</option>
                                <option value="feedback">Retour d'expérience</option>
                                <option value="question">Question</option>
                                <option value="suggestion">Suggestion</option>
                            </select>
                        </label>

                        <label>
                            Description
                            <textarea name="message" placeholder="Description" required></textarea>
                        </label>
                        <ValidationError prefix="Message" field="message" errors={state.errors} />

                        <button type="submit" className="submit-btn" disabled={state.submitting}>Envoyer</button>
                    </form>
                </div>
            </section>

            <Faq />
            <Footer /> 
        </div>
    );
}

export default Contact;