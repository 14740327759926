import React from 'react';
import '../../styles/HomeContent.css';
import profileImage from '../../assets/images/14.png';
//import avatar1 from '../../assets/images/14.png';
//import avatar2 from '../../assets/images/14.png';
import Faq from '../Faq/Faq.js';
import Footer from '../Footer/Footer.js';
import Services from '../Services/Services.js';
import Articles from '../News/News.js';


function HomeContent() {

    return (
        <div className="home-content">
            <section className="intro-section">
                <div className="intro-text">
                    <h2 className="intro-title">L’association Aides et Démarches Pour Tous vous accompagne</h2>
                    <p className="intro-subtitle">Dans le monde complexe des démarches administratives et juridiques, il est facile de se sentir perdu. 
                    C'est là que l’association Aides et Démarches Pour Tous intervient. Que vous soyez confronté à des défis liés au travail, au logement, à la vieillesse 
                    ou à d'autres domaines, nous sommes là pour vous éclairer. Nous comprenons que tout le monde n'est pas à l'aise avec l'informatique, 
                    c'est pourquoi nous offrons également un accompagnement pour les demandes en ligne et la rédaction de documents. Notre mission est de vous informer, 
                    vous accompagner et vous soutenir dans la reconnaissance et la réclamation de vos droits. 
                    Si vous ressentez le besoin d'une main amicale et experte pour vous guider, <strong>n'hésitez pas à nous contacter</strong>.</p>
                </div>
                <div className='intro-image-avis'>
                    <div className="circle circle-filled"></div>  {/* Premier cercle avec fond */}
                    <div className="circle circle-bordered"></div>  {/* Deuxième cercle avec bordure */}

                    {/* <div className='avis avis-top-right'>
                        <img src={avatar1} alt="Avatar 1" className="avis-avatar" />
                        <div className="avis-content">
                            <strong>Jean Dupont</strong>
                            <p className='avis-paragraphe'>Notre team est heureuse de vous accueiller pour vous aider à réclamer vos droits</p>
                        </div>
                    </div>
                    <div className='avis avis-bottom-left'>
                        <img src={avatar2} alt="Avatar 2" className="avis-avatar" />
                        <div className="avis-content">
                            <strong>Marie Durand</strong>
                            <p className='avis-paragraphe'>Notre team est heureuse de vous accueiller pour vous aider à réclamer vos droits</p>
                        </div>
                    </div> */}
                    <img className='profile-image' src={profileImage} alt="Description" />
                </div>
            </section>

            <Articles />
            
            <Services />
            <Faq />
            <Footer />  
        </div>
    );
}

export default HomeContent;