import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import '../../styles/ResetPage.css';
import Footer from '../Footer/Footer.js';

function ResetPage() {
    const navigate = useNavigate();
    const { token } = useParams(); // Extrait le token de l'URL

    const handleSubmit = async (e) => {
        e.preventDefault();

        const password = document.getElementById('password').value;
        const confirmPassword = document.getElementById('confirmPassword').value;

        if (password !== confirmPassword) {
            alert("Les mots de passe ne correspondent pas !");
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/reset-password`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ recoveryToken: token, newPassword: password }) // Envoi du token et du nouveau mot de passe
            });
            
            if (response.status === 200) {
                navigate('/password-reset-confirmation');
            }
        } catch (err) {
            console.error(err.message);
        }
    }

    return (
        <div>
            <div className="reset-container">
                <h2>Réinitialisation du mot de passe</h2>
                <form className="reset-form" onSubmit={handleSubmit}>
                    <div className="input-field">
                        <label htmlFor="password">Nouveau mot de passe:</label>
                        <input type="password" id="password" required />
                    </div>
                    <div className="input-field">
                        <label htmlFor="confirmPassword">Confirmez le mot de passe:</label>
                        <input type="password" id="confirmPassword" required />
                    </div>
                    <button type="submit" className="reset-button">Réinitialiser</button>
                </form>
            </div>

            <Footer /> 
        </div>
        
    );
}

export default ResetPage;
