import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function InformationsComplementairesPage() {
    const [form, setForm] = useState({
        civilite: '',
        date_naissance: '',
        adresse: '',
        nationalite: ''
    });
    const navigate = useNavigate();

    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Envoyer les informations au backend pour mise à jour
        try {
            const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/users/updateInfo`, form, {
                withCredentials: true // Assurez-vous que les cookies sont envoyés avec la requête si nécessaire
            });
            if (response.data.success) {
                navigate('/mon-compte'); // Rediriger vers la page du compte ou autre
            }
        } catch (error) {
            console.error("Erreur lors de la mise à jour des informations", error);
        }
    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <label>
                    Sexe:
                    <input type="text" name="civilité" value={form.civilite} onChange={handleChange} />
                </label>
                <label>
                    Date de naissance:
                    <input type="date" name="dateNaissance" value={form.date_naissance} onChange={handleChange} />
                </label>
                <label>
                    Adresse:
                    <input type="text" name="adresse" value={form.adresse} onChange={handleChange} />
                </label>
                <label>
                    Nationalité:
                    <input type="text" name="nationalite" value={form.nationalite} onChange={handleChange} />
                </label>
                <button type="submit">Valider les informations</button>
            </form>
        </div>
    );
}

export default InformationsComplementairesPage;
