import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import DOMPurify from 'dompurify';
import '../../styles/NewsDetails.css'
import Footer from '../Footer/Footer';

const NewsDetails = ({ match }) => {
    const [article, setArticle] = useState({});
    const { articleTitle } = useParams();

    useEffect(() => {
        const fetchNewsDetails = async () => {
            const formattedTitle = articleTitle.replace(/-/g, ' ');
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/articles/articles/title/${formattedTitle}`, { withCredentials: true });
                const cleanDescription = DOMPurify.sanitize(response.data.content);
                setArticle({ ...response.data, content: cleanDescription });
            } catch (error) {
                console.error('Erreur lors du chargement des détails de l"article:', error);
            }
        };

        fetchNewsDetails();
    }, [articleTitle]);

    return (
        <div>
            <div className="news-details-container">
                <h1 className="news-details-title">{article.titre}</h1>
                <p className="news-details-sub-description">{article.sub_description}</p>
                <hr className="news-details-separator" />
                <div className="news-details-content" dangerouslySetInnerHTML={{ __html: article.content }} />
        
                <div className="news-details-hashtags">
                    <p className="news-details-sub-description">
                        {article.hashtags && article.hashtags.map((hashtag, index) => (
                            <span key={index}>#{hashtag.name}{index < article.hashtags.length - 1 ? ', ' : ''}</span>
                        ))}
                    </p>
                </div>
        
                <hr className="news-details-separator" />
                <div className="news-details-sources">
                    <h2 className="news-details-sources-title">Sources</h2>
                    <ul className="news-details-sources-list">
                        {article.sources && article.sources.map((source, index) => (
                            <li key={index}><a href={source.name} target="_blank" rel="noopener noreferrer">{source.name}</a></li>
                        ))}
                    </ul>
                </div>
        
                <hr className="news-details-separator" />
                <div className="news-details-footer">
                    <span className="news-details-date">Le {new Date(article.date).toLocaleDateString()}</span>
                    <span className="news-details-author">Écrit par: Lounat</span>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default NewsDetails;
