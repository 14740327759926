import React, { useState, useEffect } from 'react';
import arrow from '../../assets/images/arrow.png';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../../styles/Services.css';


function Services() {
    const [services, setServices] = useState([]);
    const navigate = useNavigate();


    useEffect(() => {
        const fetchServices = async () => {
            try {
                axios.get(`${process.env.REACT_APP_BACKEND_URL}/services/services`, { withCredentials: true })
                .then(response => {
                    setServices(response.data);
                })

            } catch (error) {
                console.error('Erreur lors du chargement des services:', error);
            }
        };
    
        fetchServices();
    }, []);

    const handleServiceClick = (serviceTitle) => {
        const urlSafeTitle = encodeURIComponent(serviceTitle.toLowerCase().replace(/\s+/g, '-'));
       navigate(`/services/${urlSafeTitle}`);
    };

    return (
        <section className="services-section">
            <h2 className="services-title">Nos Services</h2>
            <p className="services-subtitle">À l'association Aides et Démarches Pour Tous, nous comprenons les défis auxquels vous pouvez être confronté 
            lors de la navigation dans le paysage administratif complexe. C'est pourquoi nous proposons une gamme complète de services conçus pour vous guider, 
            vous informer et vous soutenir à chaque étape. Que vous ayez besoin d'une assistance pour remplir des formulaires en ligne, d'une orientation juridique ou 
            d'un soutien dans des domaines tels que le logement, le travail ou les droits des étrangers, notre équipe dévouée est là pour vous aider. 
            Plongez dans nos services pour découvrir comment nous pouvons faciliter votre parcours administratif en France.</p>
            
            <div className="services-cards">
                {services.map((service) => (
                    <div className="service-card" key={service.id} onClick={() => handleServiceClick(service.title)}>                        
                        <h3>{service.title}</h3>
                        <p>{service.sub_description}</p>
                        <img src={arrow} alt="Voir plus" className="arrow-icon-services" />
                    </div>
                ))}
            </div>
        </section>
    );
}

export default Services;
