import React from 'react';
import Faq from '../Faq/Faq'; // Assurez-vous que le chemin d'importation est correct
import Footer from '../Footer/Footer'; // Assurez-vous que le chemin d'importation est correct
import { Player } from '@lottiefiles/react-lottie-player';
import './Payment.css'; // Assurez-vous de créer ce fichier CSS

const ConfirmationPayment = () => {
    return (
        <div>
            <div className="confirmation-container">
                <div className="confirmation-message">
                    <h2>Paiement Réussi!</h2>
                    <p>Votre paiement a été effectué avec succès. Merci de votre confiance.</p>
                    <div className="confirmation-icon">
                        <Player
                            autoplay
                            loop
                            src="https://lottie.host/173a353a-7b67-487e-8947-ef96815f582b/mZLIF84gGT.json"
                            style={{ height: '200px', width: '200px' }}
                        />
                    </div>
                </div>
            </div>

            <Faq />
            <Footer />
        </div>
        
    );
};

export default ConfirmationPayment;
