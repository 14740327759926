import React, { useEffect } from 'react';
import Footer from '../Footer/Footer.js';
import '../../styles/ConfirmationPage.css';
import { useNavigate } from 'react-router-dom';

function ConfirmationInscription() {
    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => navigate('/connexion'), 5000);
    }, [navigate]);

    return (
        <div>
            <div className="confirmation-container">
                <h1>Inscription réussie !</h1>
                <p>Votre compte a bien été validé ! Vous allez être redirigé vers la page de connexion.</p>
                <div className="pulse-circle"></div>
            </div>
            <Footer /> 
        </div>
    );
}

export default ConfirmationInscription;