import React, { useState, useEffect } from 'react';
import arrow from '../../assets/images/arrow.png';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../../styles/Services.css';
import Pnews from '../../assets/images/actualité.png';

function formatDate(dateString) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('fr-FR', options);
}

function Articles() {
    const [news, setNews] = useState([]);
    const [hasNews, setHasNews] = useState(false); // Par défaut, on considère qu'il n'y a pas d'actualités
    const navigate = useNavigate();


    useEffect(() => {
        const fetchNews = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/articles/lastArticles`, { withCredentials: true });
                setNews(response.data);
                setHasNews(response.data.length > 0); // Mettre à jour hasNews en fonction de la présence de news
            } catch (error) {
                console.error('Erreur lors du chargement des services:', error);
            }
        };
    
        fetchNews();
    }, []);

    const handleArticleClick = (articleTitle) => {
        // Remplacer les espaces par des tirets et encoder le titre
        const urlSafeTitle = encodeURIComponent(articleTitle.toLowerCase().replace(/\s+/g, '-'));
        navigate(`/actualite/${urlSafeTitle}`);
    };

    const handlePlusClick = () => {
            navigate('/actualite'); 
    };

    return (
        <section className="news-section">
            <div className='news-text'>
                <h2 className="news-title">Actualités</h2>
                <p className="news-subtitle">Restez informé avec les dernières actualités de l'association Aides et Démarches Pour Tous. 
                Ici, nous partageons des mises à jour importantes concernant les changements administratifs, 
                les événements à venir de l'association, les témoignages des bénéficiaires et bien plus encore. 
                Gardez un œil sur cette section pour ne rien manquer de ce qui façonne le monde des droits et des démarches en France.</p>
            </div>
            
            {hasNews ? (
                <div className="news-items">
                    {news.map((article) => (
                        <div key={article.id} className="news-item" onClick={() => handleArticleClick(article.titre)}>
                            {/* Modifier les attributs src et alt en fonction des données de l'article */}
                            <img crossOrigin="anonymous" src={article.image_path ? `${process.env.REACT_APP_BACKEND_URL}/${article.image_path}` : Pnews} 
                                    alt={article.titre}  className='photo-news'/>
                            <h3>{article.titre}</h3>
                            <p>{article.sub_description}</p>
                            <p className="article-date">{formatDate(article.date)}</p>
                            <img src={arrow} alt="Voir plus" className="arrow-icon desktop-only" />
                            <button className="btn-more mobile-only">Voir plus</button>
                        </div>
                    ))}
                </div>
            ) : (
                <div className="no-news">
                    <p>Pas d'actualité pour le moment. Revenez bientôt pour des mises à jour !</p>
                </div>
            )}

            <div className="news-button-container">
                <button className="nav-button-plus" onClick={handlePlusClick}>Voir Plus</button>
            </div>
        </section>
    );
}

export default Articles;