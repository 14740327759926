import React, { useState  } from 'react';
import { TextField, Button } from "@mui/material";
import axios from 'axios';
import ReactQuill, { Quill } from 'react-quill';
import ImageResize from 'quill-image-resize';
import 'react-quill/dist/quill.snow.css';
import './adminService.css';
import { Snackbar, Alert } from "@mui/material";

Quill.register('modules/imageResize', ImageResize);
const ServiceForm = () => {
  const [formKey, setFormKey] = useState(Date.now());
  const [service, setService] = useState({
    title: '',
    subDescription: '',
    description: '',
    keywords: '',
    // autres champs si nécessaire
  });
  const modules = {
    imageResize: {
        modules: [ 'Resize', 'DisplaySize', 'Toolbar' ]
    },
    toolbar: [
      [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
      [{ 'size': [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, 
       {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image', 'video'],
      ['clean']
    ],
  };
  const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video'
  ];
  const [openSnackbar, setOpenSnackbar] = useState(false);

  // Mettre à jour l'état du service lors des changements dans le formulaire
  const handleChange = (e) => {
    setService({ ...service, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const serviceData = {
        title: service.title,
        subDescription: service.subDescription,
        description: service.description,
        keywords: service.keywords,
        // autres données du service
    };

    try {
        const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_ADMIN_URL}/services`, 
            serviceData,
            { withCredentials: true }
        );
        if (response.status === 200 || response.status === 201) {
          setOpenSnackbar(true);
          setService({
            title: '',
            subDescription: '',
            description: '',
            keywords: ''
          });
          setFormKey(Date.now());
        }
    } catch (error) {
        console.error('Erreur lors de l\'envoi des données du service:', error);
    }
};

  const handleEditorChange = (content) => {
    setService({ ...service, description: content });
  };

  return (
    <form onSubmit={handleSubmit} key={formKey}>
        <div className='textfield'>
            <TextField 
            label="Titre" 
            name="title" 
            value={service.title} 
            onChange={handleChange}
            variant="filled" 
            color="secondary"
            size='medium'
            margin="normal"
            fullWidth
            required
            />
            <div className='others'>
                <div className='ss-description'>
                    {/* Champ pour la sous-description */}
                    <TextField 
                        label="Sous-description" 
                        name="subDescription" 
                        value={service.subDescription} 
                        onChange={handleChange}
                        variant="filled" 
                        color="secondary"
                        size='medium'
                        margin="normal"
                        fullWidth
                        required
                    />
                </div>
                <div className='keywords'>
                    {/* Champ pour les mots-clés */}
                    <TextField 
                        label="Mots-clés" 
                        name="keywords" 
                        value={service.keywords} 
                        onChange={handleChange}
                        variant="filled" 
                        color="secondary"
                        size='medium'
                        margin="normal"
                        fullWidth
                        required
                    />
                </div>
            </div>      
        </div>
        
        {/* Autres champs du formulaire */}
        <div className="form-container">
            <ReactQuill 
                className="react-quill-container"
                value={service.description}
                onChange={handleEditorChange}
                modules={modules}
                formats={formats} 
            />
        </div>

        <div className='submit-button'>
            <Button variant="contained" color="secondary" type="submit">Enregistrer</Button>
        </div>

        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={() => setOpenSnackbar(false)}
        >
          <Alert onClose={() => setOpenSnackbar(false)} severity="success" sx={{ width: '100%' }}>
              Nouveau Service Ajouté
          </Alert>
        </Snackbar>
    </form>
  );
};

export default ServiceForm;
