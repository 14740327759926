import React from 'react';
import { useState } from "react";
import { Routes, Route } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Box } from "@mui/material";
import { ColorModeContext, useMode } from "../../theme";
import Dashboard from "../dashboard";
import Sidebar from "../global/Sidebar";
import Topbar from "../global/Topbar";
import Form from "../form";
import Calendar from "../calendar";
import FAQ from "../faq";
import Bar from "../bar";
import Pie from "../Pie";
import Line from "../line";
import Geography from "../geography";
import Team from "../team/";
import Users from '../users';
import AdminPayment from '../AdminPayment';
import Demand from '../demand'
import Services from '../services'
import ServiceForm from '../services/ServiceForm'
import ServiceEditForm from '../services/ServiceEditForm'
import Articles from '../articles'
import ArticleForm from '../articles/ArticleForm'
import ArticleEditForm from '../articles/ArticleEditForm'
import Logs from '../logs'
import Bibliotheque from '../bibliotheque'

function AdminPanel() {
    const [theme, colorMode] = useMode();
    const [isSidebar, setIsSidebar] = useState(true);
  
    return (
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="app">
            <Topbar setIsSidebar={setIsSidebar} />
            <main className="content" style={{ display: "flex" }}>
              {isSidebar && <Sidebar isSidebar={isSidebar} />}
              <Box flexGrow={1}>
                <Routes>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/team" element={<Team />} />
                  <Route path="/users" element={<Users />} />
                  <Route path="/payment" element={<AdminPayment />} />
                  <Route path="/form" element={<Form />} />
                  <Route path="/calendar" element={<Calendar />} />
                  <Route path="/faq" element={<FAQ />} />
                  <Route path="/services" element={<Services />} />
                  <Route path="/articles" element={<Articles />} />
                  <Route path="/bar" element={<Bar />} />
                  <Route path="pie" element={<Pie />} />
                  <Route path="/line" element={<Line />} />
                  <Route path="/geography" element={<Geography />} />
                  <Route path="/demand" element={<Demand />} />
                  <Route path="/services/new" element={<ServiceForm />} />
                  <Route path="/services/:serviceId" element={<ServiceEditForm />} />
                  <Route path="/articles/new" element={<ArticleForm />} />
                  <Route path="/articles/:articleId" element={<ArticleEditForm />} />
                  <Route path="/logs" element={<Logs />} />
                  <Route path="/bibliotheque" element={<Bibliotheque />} />
                </Routes>
              </Box>
            </main>
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
    );
}

export default AdminPanel;
