import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation, useNavigate  } from 'react-router-dom';
import Cookies from 'js-cookie';
import '../../styles/Header.css';
import UserContext from '../Others/UserContext';
import logo from '../../assets/images/logo.png';
import { Badge, IconButton, Dialog, List, ListItem, ListItemText, DialogTitle, Avatar, Typography, ListItemAvatar, ListItemSecondaryAction } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/fr';

moment.locale('fr');
function Header() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const location = useLocation();
    const currentPage = location.pathname.replace('/', '');
    const { isUserLoggedIn, setIsUserLoggedIn, userRole } = useContext(UserContext);
    const [unreadNotifications, setUnreadNotifications] = useState(0);
    const [notifications, setNotifications] = useState([]); // Ajouté pour stocker les notifications
    const [loadingNotifications, setLoadingNotifications] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const navigate = useNavigate();

    const toggleMenu = () => {
        const nav = document.querySelector('.navbar');
        nav.style.left = isMenuOpen ? '-250px' : '0';
        setIsMenuOpen(!isMenuOpen);
    };

    const handleOpenDialog = () => {
        setIsDialogOpen(true);
        // Marquer les notifications comme lues ici si nécessaire
        markNotificationsAsRead();
    };
    
    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };

    useEffect(() => {
        const token = Cookies.get('token');
        if (token) {
            setIsUserLoggedIn(true);
        }
    }, [setIsUserLoggedIn]);

    useEffect(() => {
        if (isUserLoggedIn) {
            setLoadingNotifications(true);
            axios.get(`${process.env.REACT_APP_BACKEND_URL}/notifications/unread-count`, {
                withCredentials: true,
            })
            .then(response => {
                setUnreadNotifications(response.data.unreadCount);
            })
            .catch(error => console.error("Failed to load unread notifications count", error))
            .finally(() => setLoadingNotifications(false));
        }
    }, [isUserLoggedIn]);

    useEffect(() => {
        if (isUserLoggedIn) {
            axios.get(`${process.env.REACT_APP_BACKEND_URL}/notifications/user-notifications`, {
                withCredentials: true,
            })
            .then(response => {
                setNotifications(response.data); // Si l'API renvoie directement un tableau
            })
            .catch(error => console.error("Failed to load notifications", error));
        }
    }, [isUserLoggedIn]);

    const markNotificationsAsRead = () => {
        if (isUserLoggedIn) {
            axios.put(`${process.env.REACT_APP_BACKEND_URL}/notifications/mark-all-as-read`, {}, {
                withCredentials: true,
            })
            .then(response => {
                console.log(response.data.message);
                // Après avoir marqué comme lues, mettez à jour l'état pour refléter le changement dans l'UI
                setUnreadNotifications(0);
                // Optionnellement, actualiser les notifications pour refléter leur nouveau statut
                // fetchNotifications();
            })
            .catch(error => console.error("Failed to mark notifications as read", error));
        }
    };

    const handleNotificationClick = (notification) => {
        handleCloseDialog();
        const url = `/chat/${notification.conversation_id}/${notification.demande_id}`;
        navigate(url);
    };

    const handleLogout = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users/logout`, {
                method: "POST",
                credentials: 'include',
            });
    
            const data = await response.json();
            if (data.success) {
                setIsUserLoggedIn(false);
                Cookies.remove('token'); // Assurez-vous de supprimer le token
                window.location.href = "/";
            }
        } catch (err) {
            console.error(err.message);
        }
    };

    const handleDeleteNotification = async (notificationId) => {
        try {
            await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/notifications/delete/${notificationId}`, {
                withCredentials: true,
            });
            console.log('Notification deleted successfully');
            // Supprimez la notification de l'état après la suppression réussie
            setNotifications(notifications.filter(notification => notification.id !== notificationId));
        } catch (error) {
            console.error('Erreur lors de la suppression de la notification:', error);
        }
    };

    //rendu du composant Header
    return (
        <div>
            <header className="header">
                <img src={logo} alt="Aide et démarche pour tous" className='logo' />
                <button className="burger-menu" onClick={toggleMenu}>
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                </button>

                <nav className={`navbar ${isMenuOpen ? 'open' : ''}`}>
                    {/* Les liens de navigation. La classe 'active' est ajoutée si la page correspond à l'URL actuelle. */}
                    <Link to="/" className={`nav-button ${currentPage === '' ? 'active' : ''}`} onClick={toggleMenu}>Accueil</Link>
                    <Link to="/contact" className={`nav-button ${currentPage === 'contact' ? 'active' : ''}`} onClick={toggleMenu}>Contact</Link>
                    <Link to="/demande" className={`nav-button ${currentPage === 'demande' ? 'active' : ''}`} onClick={toggleMenu}>Faire une Demande</Link>
                    <Link to="/adherer" className={`nav-button ${currentPage === 'adherer' ? 'active' : ''}`} onClick={toggleMenu}>Adhérer</Link>
                    {isUserLoggedIn ? (
                        // Si l'utilisateur est connecté, affichez ces liens
                        <>
                            <Link to="/mon-compte" className={`nav-button connexion ${currentPage === 'mon-compte' ? 'active' : ''}`} onClick={toggleMenu}>Mon compte</Link>
                            <Link to="/deconnexion" className={`nav-button inscription ${currentPage === 'deconnexion' ? 'active' : ''}`} onClick={handleLogout}>Déconnexion</Link>
                        </>
                    ) : (
                        // Sinon, affichez ces liens
                        <>
                            <Link to="/inscription" className={`nav-button inscription ${currentPage === 'inscription' ? 'active' : ''}`}>S'inscrire</Link>
                            <Link to="/connexion" className={`nav-button connexion ${currentPage === 'connexion' ? 'active' : ''}`}>Connexion</Link>
                        </>
                    )}
                    {isUserLoggedIn && (
                        <><IconButton color="inherit" onClick={() => { handleOpenDialog(); markNotificationsAsRead(); }}>
                            <Badge badgeContent={unreadNotifications} color="secondary">
                                <NotificationsIcon />
                            </Badge>
                        </IconButton>
                        <Dialog onClose={handleCloseDialog} open={isDialogOpen} fullWidth maxWidth="sm">
                            <DialogTitle style={{ backgroundColor: '#00A18B', color: 'white', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography variant="h6" component="div" style={{ color: 'white' }}>Notifications</Typography>
                                <IconButton
                                    aria-label="close"
                                    onClick={handleCloseDialog}
                                    style={{ color: 'white' }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </DialogTitle>
                            <List>
                                {notifications.length > 0 ? (
                                    notifications.map((notification, index) => (
                                        <ListItem alignItems="flex-start" button onClick={() => handleNotificationClick(notification)} key={index} style={{ borderBottom: '1px solid #eee' }}>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <NotificationsIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={notification.message}
                                                secondary={
                                                    <React.Fragment>
                                                        <Typography component="span" variant="body2" style={{ display: 'block' }} color="textPrimary">
                                                            {moment(notification.created_at).fromNow()}
                                                        </Typography>
                                                        Cliquez pour voir plus
                                                    </React.Fragment>
                                                }
                                            />
                                            <ListItemSecondaryAction>
                                                <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteNotification(notification.id)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    ))
                                ) : (
                                    <ListItem>
                                        <ListItemText primary="Pas de notification pour le moment." />
                                    </ListItem>
                                )}
                            </List>
                        </Dialog>
                        </>
                    )}
                    {isUserLoggedIn && userRole === 4 && (
                        <Link to="/admin" className={`nav-button administration ${currentPage === 'admin' ? 'active' : ''}`} onClick={toggleMenu}>Administration</Link>
                    )}
                </nav>
            </header>
        </div>
    );
}

export default Header;