import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import UserContext from './Others/UserContext'; // Assurez-vous que le chemin est correct

const PrivateRoute = ({ children }) => {
    const { isUserLoggedIn } = useContext(UserContext);

    return isUserLoggedIn ? children : <Navigate to="/connexion" />;
};

export default PrivateRoute;
