import React from 'react';
import Footer from '../Footer/Footer.js';
import '../../styles/ConfirmationPage.css';

function ConfirmationPage() {
    return (
        <div>
            <div className="confirmation-container">
                <h1>Inscription réussie !</h1>
                <p>Merci de vous être inscrit. Un email de confirmation a été envoyé à votre adresse. Veuillez le vérifier pour activer votre compte.</p>
                <div className="pulse-circle"></div>
            </div>
            <Footer /> 
        </div>
    );
}

export default ConfirmationPage;