import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import UserContext from '../Others/UserContext';

function PrivateAdminRoute({ children }) {
  const { isUserLoggedIn, userRole, isLoading } = useContext(UserContext);

  if (isLoading) {
    return <div>Chargement...</div>; // Ou tout autre indicateur de chargement
  }

  if (!isUserLoggedIn || userRole !== 4) {
    // Redirigez vers la page de connexion ou toute autre page
    return <Navigate to="/" />;
  }

  return children;
}


export default PrivateAdminRoute;