import React from 'react';
import '../../styles/Footer.css';

function Footer() {
    return (
        <footer className="footer">
            <div className="footer-section" id="about-us">
                <h2 className="footer-heading">Aides et Démarches Pour Tous</h2>
                <p className='footer-description'>Nous sommes une association dédiée à offrir un soutien dans les démarches administratives et sociales.  
                Avec nous, vous n'êtes jamais seul dans vos démarches.</p>
            </div>
            <div className="footer-section" id="links">
                <h3 className="footer-heading">Liens</h3>
                <ul className="footer-links">
                    <li><a href="/">Accueil</a></li>
                    <li><a href="/contact">Contact</a></li>
                    <li><a href="/politique-de-confidentialite">Politique de Confidentialité</a></li>
                    <li><a href="/adherer">Adhérer</a></li>
                    <li><a href="/inscription">Inscription</a></li>
                    <li><a href="/connexion">Connexion</a></li>
                </ul>
            </div>
            <div className="footer-section" id="newsletter">
                <h3 className="footer-heading">Newsletter</h3>
                <form className="newsletter-form">
                    <input type="email" placeholder="Votre email" />
                    <button type="submit">S'abonner</button>
                </form>
            </div>
        </footer>
    );
}

export default Footer;