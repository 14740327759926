import React, { useState } from 'react';
import '../../styles/Faq.css';

function Faq() {
    const [openIndex, setOpenIndex] = useState(null); // L'index de l'élément déplié, null si aucun n'est déplié

    const faqs = [
        {
            question: "Je n'arrive pas à contacter l'association",
            answer: "Nous sommes désolés pour le désagrément. Nous faisons de notre mieux pour répondre rapidement à toutes les demandes. Si le problème persiste, nous vous prions de réessayer ultérieurement. Merci pour votre compréhension."
        },
        {
            question: "Peut-on devenir bénévole ?",
            answer: "Bien sûr ! Nous accueillons toujours avec enthousiasme les individus désireux de donner de leur temps et de leur expertise pour soutenir notre cause. Être bénévole au sein de notre association est une excellente manière de contribuer à la communauté et d'aider à faire une différence. Pour devenir bénévole, veuillez nous contacter directement via notre page de contact pour connaître les opportunités disponibles et la marche à suivre."
        },
        {
            question: "Comment adhérer à l'association",
            answer: "Actuellement, notre site web ne permet pas encore d'adhérer directement en ligne. Si vous souhaitez rejoindre notre association, nous vous invitons à nous contacter directement via notre page de contact. Nous vous fournirons toutes les informations nécessaires et les étapes à suivre pour devenir membre. Merci de votre intérêt pour notre association, et nous sommes impatients de vous accueillir parmi nous !"
        },
        {
            question: "Quelles sont les services que vous proposez ?",
            answer: "Notre association propose une variété de services destinés à soutenir et accompagner nos membres. Parmi ces services, nous offrons des conseils et orientations, des ateliers de formation, des événements de sensibilisation, ainsi que des programmes de soutien spécifiques. Nous travaillons également en étroite collaboration avec d'autres organisations pour maximiser l'impact de nos actions. Pour une liste complète et détaillée de nos services, nous vous invitons à consulter la section 'Services' de notre site web ou à nous contacter directement via notre page de contact."
        },
        // Ajoutez plus de questions/réponses au besoin
    ];

    const handleToggle = (index) => {
        setOpenIndex(openIndex === index ? null : index); // Si l'index est déjà ouvert, fermez-le, sinon ouvrez-le
    };

    return (
        <section className="faq-section">
            <h2 className='faq-title'>Des questions ?</h2>
            <p className='faq-description'>Vous pouvez retrouver ci-dessous une liste de questions fréquemment posées.</p>
            {faqs.map((faq, index) => (
                <div 
                    className="faq-item" 
                    key={index} 
                    onClick={() => handleToggle(index)}
                >
                    <button className="toggle-btn">{openIndex === index ? '-' : '+'}</button>
                    <p className='faq-question-title'>{faq.question}</p>
                    {openIndex === index && <p className='faq-question-answer'>{faq.answer}</p>}
                </div>
            ))}
        </section>
    );
}

export default Faq;