// ServiceDetails.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import DOMPurify from 'dompurify';
import '../../styles/ServiceDetails.css'
import Footer from '../Footer/Footer';

const ServiceDetails = ({ match }) => {
    const [service, setService] = useState({});
    const {serviceTitle} = useParams();

    useEffect(() => {
        const fetchServiceDetails = async () => {
            const formattedTitle = serviceTitle.replace(/-/g, ' ');
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/services/services/title/${formattedTitle}`, { withCredentials: true });
                const cleanDescription = DOMPurify.sanitize(response.data.description);
                setService({ ...response.data, description: cleanDescription });
            } catch (error) {
                console.error('Erreur lors du chargement des détails du service:', error);
            }
        };

        fetchServiceDetails();
    }, [serviceTitle]);

    return (
       <div>
        <div className="service-details-container">
            <h1 className="service-details-title">{service.title}</h1>
            <div className="service-details-content" dangerouslySetInnerHTML={{ __html: service.description }} />
        </div>
        <Footer />       
</div>
    );
};

export default ServiceDetails;
