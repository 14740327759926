import { createContext } from 'react';

const UserContext = createContext({
    isUserLoggedIn: false,
    isLoading: true,
    userId: null,
    userRole: null,
    demandeId: null,
    setIsUserLoggedIn: () => {},
    setUserRole: () => {},
    setUserId: () => {},
    setIsLoading: () => {},
    setDemandeId: () => {},
  });

export default UserContext;
