import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../styles/Demande.css';
import Services from '../Services/Services.js';
import Faq from '../Faq/Faq.js';
import Footer from '../Footer/Footer.js';
import axios from 'axios';
import UserContext from '../Others/UserContext.js';

const Demande = () => {
  const { setDemandeId } = useContext(UserContext);
  const [typeDemande, setTypeDemande] = useState('');
  const [titre, setTitre] = useState('');
  const [description, setDescription] = useState('');
  const [document, setDocument] = useState(null);
  const navigate = useNavigate();

  const handleTypeChange = (e) => {
    setTypeDemande(e.target.value);
  };

  const handleTitreChange = (e) => {
    setTitre(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleDocumentChange = (e) => {
    setDocument(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('typeDemande', typeDemande);
    formData.append('titre', titre);
    formData.append('description', description);
    if (document) {
      formData.append('document', document);
    }

    try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/demandes/demandes`, formData, {
        withCredentials: true,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
        });

        if (response.status === 200 || response.status === 201) {
          const demandeId = response.data.demandeId;
          setDemandeId(demandeId);
          const conversationId = response.data.conversationId;
          navigate(`/chat/${conversationId}/${demandeId}`);
        } else {
          console.log('Statut de réponse non attendu:', response.status);
          console.log('Texte de réponse:', response.statusText);
          // Vous pouvez également vérifier la réponse.data si nécessaire
        }
    } catch (error) {
        console.error('Une erreur est survenue lors de l\'envoi du formulaire:', error);
        alert('Une erreur est survenue lors de l\'envoi du formulaire.');
    }
  };

  return (
    <div>
      <section className="demande-section">
        <div className="demande-container">
          <h1 className="demande-title">Faire une demande</h1>
          <p className="demande-description">
            Vous avez besoin d’aide ou de conseil ? <br />
            Faites votre demande ci-dessous et nous vous contacterons le plus rapidement possible.
          </p>
          <form className="demande-form" onSubmit={handleSubmit}>
            <label>
              Type de demande:
              <select name="typeDemande" value={typeDemande} onChange={handleTypeChange} required>
                <option value="">--Choisir une option--</option>
                <option value="droit_travail">Droit du travail</option>
                <option value="aide_sociale">Aide sociale</option>
                <option value="demarches_administratives">Démarches administratives</option>
                <option value="personne_agee">Personne agées</option>
                <option value="jeunesse">Jeunesse</option>
                <option value="handicap">Handicap</option>
                {/* Ajoutez plus d'options ici si nécessaire */}
              </select>
            </label>
            <label>
              Titre de la demande:
              <input type="text" name="titre" value={titre} onChange={handleTitreChange} required />
            </label>
            <label>
              Description de la situation:
              <textarea name="description" value={description} onChange={handleDescriptionChange} required></textarea>
            </label>
            <label>
              Joindre un document (facultatif):
              <input type="file" name="document" onChange={handleDocumentChange} />
            </label>
            <button type="submit" className="demande-submit-btn">Envoyer la demande</button>
          </form>
        </div>
      </section>

      <Services />
      <Faq />
      <Footer /> 
    </div>
  );
};

export default Demande;
