import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HeaderOrTopbar from './components/Header/HeaderOrTopbar';
import './styles/App.css';
import Accueil from './components/Accueil/Accueil';
import Contact from './components/Contact/Contact';  // Importez le composant Contact
import Adherer from './components/Adherer/Adherer';
import InscriptionPage from './components/Form/InscriptionPage';
import LoginPage from './components/Form/LoginPage'
import ConfirmationPage from './components/Form/ConfirmationPage';
import InscriptionConfirmationPage from './components/Form/InscriptionConfirmationPage';
import InformationsComplementairesPage from './components/Form/InformationsComplementairesPage';
import RecoveryPage from './components/Form/RecoveryPage';
import ResetPage from './components/Form/ResetPage';
import ConfirmationReset from './components/Form/ConfirmationReset';
import PasswordResetConfirmation from './components/Form/PasswordResetConfirmation';
import MyAccount from './components/Account/MyAccount';
import UserContext from './components/Others/UserContext';
import Demande from './components/Demande/DemandPage';
import AdminPanel from './scenes/admin/AdminPanel';
import PrivateAdminRoute from './components/Admin/PrivateAdminRoute';
import PaymentPage from './components/Payment/PaymentPage';
import PrivateRoute from './components/PrivateRoute';
import ConfirmationPayment from './components/Payment/ConfirmationPayment';
import ChatPage from './components/Chat/ChatPage';
import PrivateAdherentRoute from './components/PrivateAdherentRoute';
import ServiceDetails from './components/Services/ServiceDetails';
import NewsDetails from './components/News/NewsDetails';
import AllNews from './components/News/AllNews';
import PrivacyPolicy from './components/Confidentiality/PrivacyPolicy';
import ConfirmationInscription from './components/Form/ConfirmationInscription';

function App() {
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
    const [userRole, setUserRole] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [userId, setUserId] = useState(null);
    const [demandeId, setDemandeId] = useState(null);

    useEffect(() => {
        const checkLoginStatus = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/status`, {
                    credentials: 'include',
                });
                const data = await response.json();
                setIsUserLoggedIn(data.loggedIn);
                if (data.loggedIn && data.role) {
                    setUserRole(data.role);
                    setUserId(data.userId);
                }
            } catch (err) {
                console.error(err);
            } finally {
                setIsLoading(false); // Définissez isLoading sur false une fois que l'état de connexion est vérifié
            }
        };
    
        checkLoginStatus();
    }, []);
    
    return (
        <UserContext.Provider value={{ isUserLoggedIn, setIsUserLoggedIn, userRole, setUserRole, isLoading, userId, setUserId, demandeId, setDemandeId  }}>
            <Router>
                <div className="container">
                    <HeaderOrTopbar />
                    <main className="main-content">
                        <Routes>
                            <Route path="/" element={<Accueil />} exact />
                            <Route path="/contact" element={<Contact />} />
                            <Route path="/Adherer" element={<Adherer />} />
                            <Route path="/inscription" element={<InscriptionPage />} />
                            <Route path="/connexion" element={<LoginPage />} />
                            <Route path="/confirmation" element={<ConfirmationPage />} />
                            <Route path="/recovery" element={<RecoveryPage />} />
                            <Route path="/reset/:token" element={<ResetPage />} />
                            <Route path="/confirmationReset" element={<ConfirmationReset />} />
                            <Route path="/password-reset-confirmation" element={<PasswordResetConfirmation />} />
                            <Route path="/mon-compte" element={<PrivateRoute><MyAccount /></PrivateRoute>} />
                            <Route path="/demande" element={<PrivateAdherentRoute><Demande /></PrivateAdherentRoute>} />
                            <Route path="/admin/*" element={<PrivateAdminRoute><AdminPanel /></PrivateAdminRoute>} />
                            <Route path="/payment" element={<PrivateRoute><PaymentPage /></PrivateRoute>}  />
                            <Route path="/confirmation-payment" element={<ConfirmationPayment />} />
                            <Route path="/chat/:conversationId/:demandeId" element={<ChatPage />} />
                            <Route path="/services/:serviceTitle" element={<ServiceDetails />} />
                            <Route path="/actualite/:articleTitle" element={<NewsDetails />} />
                            <Route path="/actualite" element={<AllNews />} />
                            <Route path="/politique-de-confidentialite" element={<PrivacyPolicy />} />
                            <Route path="/activation" element={<InscriptionConfirmationPage />} />
                            <Route path="/informations-complementaires" element={<InformationsComplementairesPage />} />
                            <Route path="/confirmation-inscription" element={<ConfirmationInscription />} />
                            {/* Ajoutez d'autres routes si nécessaire */}
                        </Routes>
                    </main>
                </div>
            </Router>
        </UserContext.Provider>
        
    );
}

export default App;