import React from 'react';
import './Accueil.css';
import HomeContent from '../Home/HomeContent';
import { Helmet } from "react-helmet";

function Accueil() {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Aides et Démarches Pour Tous</title>
                <link rel="canonical" href="https://asso-adpt.fr/" />
                <meta name="description" content="On vous accompagne dans vos démarches adminstratives."/>
            </Helmet>
            <div className="container">
                <main className="main-content">
                    <HomeContent />
                </main>
            </div>
        </div>
    );
}

export default Accueil;