import React, { useState, useEffect } from 'react';
import { TextField, Button, Snackbar, Alert } from "@mui/material";
import axios from 'axios';
import ReactQuill, { Quill } from 'react-quill';
import ImageResize from 'quill-image-resize';
import 'react-quill/dist/quill.snow.css';
import './adminService.css';
import { useParams } from 'react-router-dom';

Quill.register('modules/imageResize', ImageResize);

const ServiceEditForm = () => {
  const { serviceId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [service, setService] = useState({
    title: '',
    sub_description: '',
    description: '',
    keywords: '',
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const modules = {
    imageResize: {
        modules: [ 'Resize', 'DisplaySize', 'Toolbar' ]
    },
    toolbar: [
      [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
      [{ 'size': [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, 
       {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image', 'video'],
      ['clean']
    ],
  };
  const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video', 'width'
  ];

  const handleEditorChange = (content) => {
    setService({ ...service, description: content });
  };

  // Mettre à jour l'état du service lors des changements dans le formulaire
  const handleChange = (e) => {
    setService({ ...service, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/services/services/${serviceId}`, { withCredentials: true })
      .then(response => {
        setService(response.data);
        setIsLoading(false); // Mettre à jour l'état de chargement
      })
      .catch(error => {
        console.error('Erreur lors du chargement du service:', error);
        setIsLoading(false);
      });
  }, [serviceId]);

  if (isLoading) {
    return <div>Chargement en cours...</div>;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.put(
        `${process.env.REACT_APP_BACKEND_ADMIN_URL}/services/edit/${serviceId}`,
        service,
        { withCredentials: true }
      );
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Erreur lors de la mise à jour du service:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className='textfield'>
        <TextField 
        label="Titre" 
        name="title" 
        value={service.title} 
        onChange={handleChange}
        variant="filled" 
        color="secondary"
        size='medium'
        margin="normal"
        fullWidth
        required
        />
        <div className='others'>
          <div className='ss-description'>
            {/* Champ pour la sous-description */}
            <TextField 
                label="Sous-description" 
                name="sub_description" 
                value={service.sub_description} 
                onChange={handleChange}
                variant="filled" 
                color="secondary"
                size='medium'
                margin="normal"
                fullWidth
                required
            />
          </div>
          <div className='keywords'>
            {/* Champ pour les mots-clés */}
            <TextField 
                label="Mots-clés" 
                name="keywords" 
                value={service.keywords} 
                onChange={handleChange}
                variant="filled" 
                color="secondary"
                size='medium'
                margin="normal"
                fullWidth
                required
            />
          </div>
        </div>      
      </div>
      
      {/* Autres champs du formulaire */}
      <div className="form-container">
          <ReactQuill 
              className="react-quill-container"
              value={service.description}
              onChange={handleEditorChange}
              modules={modules}
              formats={formats} 
          />
      </div>

      <div className='submit-button'>
          <Button variant="contained" color="secondary" type="submit">Enregistrer</Button>
      </div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity="success" sx={{ width: '100%' }}>
          Service Modifié avec Succès
        </Alert>
      </Snackbar>
    </form>
  );
};

export default ServiceEditForm;
