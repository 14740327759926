import React, { useContext } from 'react';
import './Adherer.css';
import Faq from '../Faq/Faq';
import Footer from '../Footer/Footer';
import { useNavigate } from 'react-router-dom';
import UserContext from '../Others/UserContext';

function Adherer() {
    const { isUserLoggedIn } = useContext(UserContext);
    const navigate = useNavigate();

    const handleAdhererClick = () => {
        if (isUserLoggedIn) {
            navigate('/payment'); // Rediriger vers la page de paiement
        } else {
            alert("Veuillez vous connecter ou vous inscrire"); // Afficher un message
        }
    };

    return (
        <div>
            <div className="adherer-container">
                <h1 className="adherer-title">Adhérer</h1>
                <div className='adherer-div-description'>
                    <p className="adherer-description">Vous souhaitez adhérer à l'association ?</p>
                    <p className="adherer-description">En adhérant à l'association, vous permettez à celle-ci de se développer !</p>
                    <p className="adherer-description">Vous bénéfieriez également de nombreux avantages.</p>
                </div>
                
                
                <div className="tables-container">
                    <div className="table">
                        <h2>Non adhérent</h2>
                        <h3>Avantages</h3>
                        <ul>
                            <li>+ Newsletter</li>
                            <li>+ Compte visiteur</li>
                            <li>+ Possibilité d'assister à certains évenements publics organisés par l'association</li>
                            <li>+ Accès à certaines ressources en ligne (articles ou vidéos)</li>
                            {/* Ajoutez plus d'avantages si nécessaire */}
                        </ul>
                    </div>
                    
                    <div className="table">
                        <h2>Adhérent</h2>
                        <h3>Avantages</h3>
                        <ul>
                            <li>+ Newsletter réservée aux adhérents</li>
                            <li>+ Compte adhérent</li>
                            <li>+ Possibilité d’assister à tous les événements publics organisés par l’association</li>
                            <li>+ Accès à des ressources en ligne exclusives ( articles ou vidéos)</li>
                            <li>+ Accès à toutes les informations de l’association</li>
                            <li>+ On vous accompagne dans vos demandes d'aides</li>
                            <li>+ Reconnaissance sur le site web ou dans les publications de l’association</li>
                            <li>+ Suivi des demandes</li>
                            {/* Ajoutez plus d'avantages si nécessaire */}
                        </ul>
                    </div>
                </div>
                <button className="nav-button-adherer" onClick={handleAdhererClick}>Adhérer</button>
            </div>

            <Faq />
            <Footer />
        </div>
    );
}

export default Adherer;
