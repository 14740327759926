import React from 'react';
import '../../styles/InscriptionPage.css';
import Faq from '../Faq/Faq.js';
import Footer from '../Footer/Footer.js';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

function InscriptionPage() {
    const navigate = useNavigate();
    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Extraction des valeurs des champs de formulaire
        const userFirstName = document.getElementById('firstname').value;
        const userName = document.getElementById('lastname').value;
        const userEmail = document.getElementById('email').value;
        const userPassword = document.getElementById('password').value;
        const userPhone = document.getElementById('telephone').value;

        const isValidPhoneNumber = (phoneNumber) => {
            // Cette regex permet de valider les numéros de téléphone français
            const phoneRegex = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/;
            return phoneRegex.test(phoneNumber);
        }

        if (!isValidPhoneNumber(userPhone)) {
            alert("Veuillez entrer un numéro de téléphone valide.");
            return;
        }
    
        try {
            const body = { userFirstName, userName, userEmail, userPassword, userPhone };
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users`, {
                method: "POST",
                credentials: 'include',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(body)
            });
    
            const jsonData = await response.json();

            if (response.status !== 401) {
                navigate("/confirmation");
            } else {
                // Gérer l'erreur d'authentification
                alert(jsonData);
            }
    
            console.log(jsonData);
        } catch (err) {
            console.error(err.message);
        }
    }

    return (
        <div>
            <div className="inscription-container">
                <div className="inscription-box">
                    <h2>Nouveau membre ?</h2>
                    <form className="inscription-form" onSubmit={handleSubmit}>
                        <div className="name-fields">
                            <div className="input-field">
                                <label htmlFor="lastname">Nom:</label>
                                <input type="text" id="lastname" required />
                            </div>
                            <div className="input-field">
                                <label htmlFor="firstname">Prénom:</label>
                                <input type="text" id="firstname" required />
                            </div>
                        </div>

                        <div className="input-field">
                            <label htmlFor="email">Email:</label>
                            <input type="email" id="email" required />
                        </div>
                        <div className="input-field">
                            <label htmlFor="password">Mot de passe:</label>
                            <input type="password" id="password" required />
                        </div>
                        <div className="input-field">
                            <label htmlFor="telephone">Téléphone:</label>
                            <input type="tel" id="telephone" required />
                        </div>
                        <button type="submit" className="inscription-button">S'inscrire</button>
                    </form>
                </div>
                <div className="login-box">
                    <h3>Déjà membre ?</h3>
                    <Link to="/connexion" className="login-page">Se connecter</Link>
                </div>
            </div>

            <Faq />
            <Footer /> 
        </div>
    );
}

export default InscriptionPage;
