import React, { useState, useEffect, useContext, useRef, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import UserContext from '../Others/UserContext';
import './ChatPage.css';
import Footer from '../Footer/Footer';
import axios from 'axios';
import FileOpenOutlinedIcon from '@mui/icons-material/FileOpenOutlined';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import io from 'socket.io-client';

// Initial state for reducer
const initialState = {
  messages: [],
  isLoading: false,
  error: null,
  isAuthorized: true
};

// Reducer function
function reducer(state, action) {
  switch (action.type) {
    case 'SET_MESSAGES':
      return { ...state, messages: action.payload };
    case 'ADD_MESSAGE':
      return { ...state, messages: [...state.messages, action.payload] };
    case 'SET_LOADING':
      return { ...state, isLoading: action.payload };
    case 'SET_ERROR':
      return { ...state, error: action.payload };
    case 'SET_AUTHORIZED':
      return { ...state, isAuthorized: action.payload };
    default:
      return state;
  }
}

const ChatPage = () => {
  const { demandeId, conversationId } = useParams();
  const { userId, userRole } = useContext(UserContext);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [message, setMessage] = useState('');
  const [titreDemande, setTitreDemande] = useState('');
  const messagesEndRef = useRef(null);
  const [statutDemande, setStatutDemande] = useState('');
  const className = `statusIndicator ${statutDemande.replace(' ', '-')}`;
  const isChatDisabled = statutDemande === 'terminé' || statutDemande === 'refusé';
  const disabledStyle = {
    backgroundColor: "#ccc", // Gris
    color: "#666", // Gris foncé
    cursor: "not-allowed", // Curseur en mode "non autorisé"
    pointerEvents: "none", // Empêche les interactions
  };
  const [file, setFile] = useState(null);  
  const fileInputRef = useRef(null);
  const [fileName, setFileName] = useState("");
  const socketRef = useRef(null);


  const handleAttachmentClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    const checkAuthorizationAndFetchStatus = async () => {
      try {
        // Vérifier l'autorisation de l'utilisateur pour la conversation
        const authResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/conversations/conversations/${conversationId}/authorize`, { withCredentials: true });
        dispatch({ type: 'SET_AUTHORIZED', payload: authResponse.data.isAuthorized });
  
        // Si autorisé, récupérer le statut de la demande
        if (authResponse.data.isAuthorized && demandeId) {
          const statusResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/demandes/demandes/${demandeId}`, { withCredentials: true });
          if (statusResponse.data && statusResponse.data.statut) {
            setStatutDemande(statusResponse.data.statut);
          }
        }
      } catch (error) {
        console.error('Erreur lors de la vérification de l’autorisation ou de la récupération du statut:', error);
        dispatch({ type: 'SET_AUTHORIZED', payload: false });
      }
    };
  
    checkAuthorizationAndFetchStatus();
  }, [conversationId, demandeId]);

  useEffect(() => {
    socketRef.current = io('https://asso-adpt.fr:3001');

    socketRef.current.on('new_message', (msg) => {
      dispatch({ type: 'ADD_MESSAGE', payload: msg });
    });

    socketRef.current.on('statut_demande_change', (data) => {
      if (data.demandeId === demandeId) {
        setStatutDemande(data.nouveauStatut);
      }
    });

    return () => {
      socketRef.current.off('new_message');
      socketRef.current.off('statut_demande_change');
      socketRef.current.disconnect();
    };
  }, [demandeId]);


  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0] ? e.target.files[0].name : "");
  };  

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // Fonction pour gérer la modification du statut
  const handleStatutChange = async (newStatut) => {
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND_URL}/demandes/demandes/${demandeId}/statut`, 
        { statut: newStatut }, 
        { withCredentials: true }
      );
      setStatutDemande(newStatut);
    } catch (error) {
      console.error("Erreur lors de la mise à jour du statut de la demande:", error);
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [state.messages]);

  const sendMessage = async (e) => {
    e.preventDefault();
  
    const formData = new FormData();
    formData.append('senderId', userId);
    formData.append('conversationId', conversationId);
    formData.append('content', message);
    formData.append('attachment', file);
  
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/messages/messages`, formData, {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const newMessage = response.data;
      socketRef.current.emit('send_message', newMessage);
      dispatch({ type: 'ADD_MESSAGE', payload: newMessage });
  
      setMessage('');
      setFile(null);
      setFileName('');
    } catch (error) {
      console.error("Erreur lors de l'envoi du message:", error);
    }
  };  

  useEffect(() => {
    const loadMessageHistory = async () => {

      dispatch({ type: 'SET_LOADING', payload: true });
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/messages/messages?conversationId=${conversationId}`, { withCredentials: true });
        console.log("Messages reçus :", response.data);
        const data = response.data;
          if (Array.isArray(data)) {
            dispatch({ type: 'SET_MESSAGES', payload: data });
            setTitreDemande(data[0].titre);
          } else {
            console.error("Format de données incorrect pour les messages", data);
            dispatch({ type: 'SET_ERROR', payload: 'Format de données incorrect' });
          }
      } catch (error) {
        console.error('Erreur lors du chargement de l\'historique des messages:', error);
        dispatch({ type: 'SET_ERROR', payload: 'Impossible de charger les messages' });
      } finally {
        dispatch({ type: 'SET_LOADING', payload: false });
      }
    };

    loadMessageHistory();
  }, [conversationId]);

  if (state.isLoading) {
    return <div>Chargement...</div>;
  }

  if (state.error) {
    return <div>Erreur: {state.error}</div>;
  }

  if (!state.isAuthorized) {
    return <div>Vous n'êtes pas autorisé à voir cette conversation.</div>;
  }

  const renderMessageItem = (msg, index) => {
    // Déterminez si le message a une pièce jointe
    const hasMessageDocument = msg.original_name && msg.path;
    // Déterminez si le premier message a une pièce jointe de la demande
    const hasDemandeDocument = index === 0 && msg.demande_file_name && msg.demande_file_path;

    let dateEnvoi = 'Date inconnue';

    if (msg.created_at) {
      const isoFormattedDate = msg.created_at.replace(' ', 'T').split('.')[0] + 'Z';
      dateEnvoi = new Date(isoFormattedDate).toLocaleString('fr-FR', {
        year: 'numeric', 
        month: 'numeric', 
        day: 'numeric'
      });
    }

    const senderName = msg.sendername || 'Utilisateur inconnu';
  
    return (
        <li key={index} className={`messageItem ${msg.sender_id === userId ? 'messageRight' : 'messageLeft'}`}>
            <div className="messageBox">
              <div className="messageContent">
                  <p className="messageText">{msg.content}</p>
              </div>
              {hasMessageDocument && (
                  <div className="fileDownload">
                      <a href={`${process.env.REACT_APP_BACKEND_URL}/${msg.path}`} download={msg.original_name} target="_blank" rel="noopener noreferrer" className="attachmentIcon">
                          <FileOpenOutlinedIcon />
                      </a>
                      <span className="fileName">{msg.original_name}</span>
                  </div>
              )}
              {hasDemandeDocument && (
                  <div className="fileDownload">
                      <a href={`${process.env.REACT_APP_BACKEND_URL}/${msg.demande_file_path}`} download={msg.demande_file_name} target="_blank" rel="noopener noreferrer" className="attachmentIcon">
                          <FileOpenOutlinedIcon />
                      </a>
                      <span className="fileName">{msg.demande_file_name}</span>
                  </div>
              )}
            </div>
            {/* Afficher le nom de l'expéditeur et la date envoi */}
            <div className="messageInfo">
                <span className="messageSender">{senderName},</span>
                <span className="messageDate">{dateEnvoi}</span>
            </div>
        </li>
    );
};


  return (
    <div>
      <h1>Chat en Direct</h1>
      <div className="chatAdminContainer">
        <div className='chatContainer'>
          <div className="chatPage">
            <div className="infoBar">
              <div className="leftInnerContainer">
                <h3>{titreDemande || "Chat en Direct"}</h3>
                <div className="statusIndicatorContainer">
                  <span className={className}></span>
                  <span className="statusTooltip">Statut : {statutDemande}</span>
                </div>
              </div>
            </div>
            <div className="messagesContainer">
            <ul id="messagesList">
              {state.messages.map(renderMessageItem)}
            </ul>
              <div ref={messagesEndRef} /> {/* Élément vide pour faire défiler jusqu'au bas */}
            </div>
            <form id="chatForm" className="messageForm" onSubmit={sendMessage} disabled={isChatDisabled}>
              <input
                id="messageInput"
                className="inputField"
                type="text"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder={isChatDisabled ? "La demande a été cloturé" : "Écrire un message..."}
                disabled={isChatDisabled}
                style={isChatDisabled ? disabledStyle : null}
              />
              <input
                  ref={fileInputRef}
                  type="file"
                  accept=".pdf"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
              />
              <button type="button" onClick={handleAttachmentClick} className="attachmentButton">
                <AttachFileOutlinedIcon />
              </button>
              {fileName && <span className="fileName">{fileName}</span>}
              <button type="submit" className="sendButton" disabled={isChatDisabled} style={isChatDisabled ? disabledStyle : null}>Envoyer</button>
            </form>
          </div>
          {
            // Afficher le sélecteur de statut uniquement si l'utilisateur est un administrateur
            (userRole === 4) && (
              <div className='adminTools'>
                <h3>Outils d'Administration</h3>
                <div>
                  <label htmlFor="statutSelect">Modifier le statut de la demande :</label>
                  <select 
                    id="statutSelect" 
                    value={statutDemande} 
                    onChange={(e) => handleStatutChange(e.target.value)}
                  >
                    <option value="en attente">En attente</option>
                    <option value="en cours">En cours</option>
                    <option value="terminé">Terminé</option>
                    <option value="refusé">Refusé</option>
                  </select>
                </div>
              </div>
            )}
        </div>
      </div>

        <Footer /> 
    </div>
  );
};

export default ChatPage;
