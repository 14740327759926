import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Payment.css';
import Faq from '../Faq/Faq';
import Footer from '../Footer/Footer';

const PaymentPage = () => {
    const [userId, setUserId] = useState(null);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/auth/user`, { withCredentials: true });
                setUserId(response.data.id);
            } catch (error) {
                console.error('Erreur lors de la récupération des données utilisateur:', error);
            }
        };

        fetchUserData();
    }, []);

    const handlePayment = async () => {
        if (!userId) {
            alert("Vous devez être connecté pour procéder au paiement.");
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/payments/create-checkout-session`, { userId }, { withCredentials: true });
            window.location.href = response.data.url; // Redirige vers la page de paiement Stripe
        } catch (error) {
            console.error('Erreur lors de la création de la session de paiement:', error);
        }
    };
    

    return (
        <div>
            <h1 className="paiement-title">Adhérer</h1>
            <div className='payment-page-container'>
                <div className='resume-section'>
                    <h2>Adhésion à l'association ADPT</h2>
                    <h3>Pourquoi adhérer ?</h3>
                    <p>Rejoindre l'association ADPT, c'est avant tout s'engager dans une communauté active et solidaire 
                        qui œuvre pour le développement personnel et professionnel de ses membres. C'est une opportunité 
                        de participer à des projets porteurs de sens, de bénéficier d'un réseau soutenant et de contribuer à des causes importantes.</p>
                    <h3>Quels sont les avantages ?</h3>
                    <p>En tant que membre adhérent, vous profiterez d'un accès exclusif à nos ateliers, séminaires et 
                        événements de networking. Vous bénéficierez également de tarifs préférentiels sur nos formations, 
                        de l'opportunité de rencontrer des experts dans divers domaines et de la possibilité de vous 
                        impliquer directement dans la vie associative pour faire entendre votre voix et celle de la communauté.</p>
                </div>
                <div class="summary-section">
                    <h3>Sommaire</h3>
                    <p>Devenir membre adhérent de l'association</p>
                    <p class="price">50,00€</p>
                    <p class="total">Total <span class="price">50,00 €</span></p>
                    <button onClick={handlePayment} className="payment-button">
                        Procéder au Paiement
                    </button>
                </div>
            </div>

            <Faq />
            <Footer />
        </div>
    );
};

export default PaymentPage;
