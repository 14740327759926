import React, { useState, useEffect } from 'react';
import { Box, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Admin/Header";
import axios from 'axios';

const Logs = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [logs, setLogs] = useState([]); // Renommé pour refléter les données affichées

  useEffect(() => {
    const fetchLogs = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_ADMIN_URL}/logs`, { withCredentials: true }); // Assurez-vous que l'URL est correcte
        setLogs(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des logs:', error);
      }
    };

    fetchLogs();
  }, []);

  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    { field: "severity", headerName: "Sévérité", width: 150 },
    { field: "message", headerName: "Message", width: 400, flex: 1 },
    { field: "user_id", headerName: "ID Utilisateur", width: 130 },
    { field: "ip_address", headerName: "Adresse IP", width: 150 },
    { field: "log_date", headerName: "Date", width: 200 },
    { field: "additional_info", headerName: "Infos Supplémentaires", width: 200, flex: 1 },
    // Vous pouvez ajouter d'autres colonnes selon la structure de vos logs
  ];

  const adaptedLogs = logs.map(log => ({
    ...log,
    id: log.log_id, // Ajouter un champ `id` utilisant la valeur de `log_id`
    log_date: new Date(log.log_date).toLocaleString(),
    additional_info: JSON.stringify(log.additional_info),
  }));

  return (
    <Box m="20px">
      <Header title="Logs" subtitle="Liste des Logs" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-row": {
              borderBottom: "none",
            },
          }}
      >
        <DataGrid rows={adaptedLogs} columns={columns} pageSize={10} />
      </Box>
    </Box>
  );
};

export default Logs;
