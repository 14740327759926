import React, { useState, useEffect } from "react";
import { Box, Grid, Card, CardMedia, CardActionArea, CardActions, Button, Typography, Modal, IconButton } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Header from "../../components/Admin/Header";
import axios from "axios"; // Assurez-vous d'avoir axios pour les requêtes HTTP
import CloseIcon from '@mui/icons-material/Close';

// Style pour le modal
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const Bibliotheque = () => {
  const [images, setImages] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  // Charger les images au démarrage
  useEffect(() => {
    fetchImages();
  }, []);

  const fetchImages = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_ADMIN_URL}/images`, { withCredentials: true });
      setImages(response.data);
    } catch (error) {
      console.error("Erreur lors de la récupération des images:", error);
    }
  };

  // Gérer l'ouverture/fermeture du modal
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Gérer la sélection du fichier
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  // Gérer l'envoi du fichier
  const handleUploadImage = async () => {
    const formData = new FormData();
    formData.append('image', selectedFile);

    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_ADMIN_URL}/upload-image`, formData, {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      alert('Image téléchargée avec succès');
      fetchImages(); // Recharger la liste des images
      handleClose(); // Fermer le modal
    } catch (error) {
      console.error('Erreur lors du téléchargement de l\'image:', error);
      alert('Erreur lors du téléchargement de l\'image');
    }
  };

  // Gérer la suppression d'une image
  const handleDeleteImage = async (imageId) => {
    try {
      await axios.delete(`${process.env.REACT_APP_BACKEND_ADMIN_URL}/images/${imageId}`, { withCredentials: true });
      alert('Image supprimée avec succès');
      setImages(images.filter(image => image.id !== imageId)); // Mettre à jour l'état local
    } catch (error) {
      console.error('Erreur lors de la suppression de l\'image:', error);
      alert('Erreur lors de la suppression de l\'image');
    }
  };

  return (
    <Box m="20px">
      <Header title="Bibliothèque d'images" subtitle="Gérez vos images facilement" />
      <Box sx={{ marginTop: 4 }}>
        <Grid container spacing={2}>
          {images.map((image) => (
            <Grid item xs={12} sm={6} md={4} key={image.id}>
              <Card>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="140"
                    crossOrigin="anonymous"
                    image={`${process.env.REACT_APP_BACKEND_URL}/${image.image_path}`}
                    alt={image.file_name}
                  />
                </CardActionArea>
                <CardActions>
                <Button
                    size="small"
                    color="primary"
                    startIcon={<DeleteIcon />}
                    onClick={() => handleDeleteImage(image.id)}
                    sx={{
                        color: 'white',
                        backgroundColor: 'primary.main', // Utilisez la couleur principale du thème pour le fond
                        '&:hover': {
                        backgroundColor: 'primary.dark', // Couleur au survol
                        }
                    }}
                    >
                    Supprimer
                </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
          <Grid item xs={12} sm={6} md={4}>
            <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleOpen} sx={{ height: '100%', width: '100%' }}>
              Ajouter une image
            </Button>
          </Grid>
        </Grid>
      </Box>

      {/* Modal pour l'ajout d'images */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Télécharger une nouvelle image
          </Typography>
          <IconButton aria-label="close" onClick={handleClose} sx={{ position: 'absolute', right: 8, top: 8 }}>
            <CloseIcon />
          </IconButton>
          <input type="file" accept="image/*" onChange={handleFileChange} />
          <Button variant="contained" color="secondary" onClick={handleUploadImage} sx={{ mt: 2 }}>
            Télécharger
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default Bibliotheque;
