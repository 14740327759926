import React from 'react';
import { useNavigate } from 'react-router-dom';  // Ajoutez cet import
import '../../styles/RecoveryPage.css';
import Footer from '../Footer/Footer.js';

function RecoveryPage() {
    const navigate = useNavigate();  // Utilisez le hook useNavigate

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const email = document.getElementById('email').value;
    
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/password-recovery`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ email })
            });
    
            const jsonData = await response.json();
             // Si la réponse est réussie, redirigez vers la page de confirmation
             if (response.ok) {
                navigate('/confirmationReset');
            } else {
                alert(jsonData);
            }
        } catch (err) {
            console.error(err.message);
        }
    }

    return (
        <div>
            <div className="recovery-container">
                <h2>Récupération de mot de passe</h2>
                <form className="recovery-form" onSubmit={handleSubmit}>
                    <div className="input-field">
                        <label htmlFor="email">Email:</label>
                        <input type="email" id="email" required />
                    </div>
                    <button type="submit" className="recovery-button">Demander la récupération</button>
                </form>
            </div>

            <Footer /> 
        </div>
        
    );
}

export default RecoveryPage;
