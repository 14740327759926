import React, { useContext, useState, useEffect  } from 'react';
import UserContext from '../../components/Others/UserContext';
import { TextField, Button, Snackbar, Typography, Alert, InputAdornment, IconButton, Modal, Grid, Card, CardMedia, Box } from "@mui/material";
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../services/adminService.css';
import { useParams } from 'react-router-dom';
import { Select, MenuItem, FormControl, Chip } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloseIcon from '@mui/icons-material/Close';

const ArticleEditForm = () => {
    const { userId } = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(true);
    const { articleId } = useParams();

    const formatDate = (date) => {
    const d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

    return [year, month.padStart(2, '0'), day.padStart(2, '0')].join('-');
    };

    const [existingSources, setExistingSources] = useState([]);
    const [selectedSources, setSelectedSources] = useState([]);
    const [newSourceName, setNewSourceName] = useState('');

    const [existingHashtags, setExistingHashtags] = useState([]);
    const [selectedHashtag, setSelectedHashtag] = useState([]);
    const [newHashtagName, setNewHashtagName] = useState('');

    const [showNewSourceInput, setShowNewSourceInput] = useState(false);
    const [showNewHashtagInput, setShowNewHashtagInput] = useState(false);

    const [images, setImages] = useState([]);
    const [imagePreviewUrl, setImagePreviewUrl] = useState('');
    const [openImageLibrary, setOpenImageLibrary] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const [article, setArticle] = useState({
        titre: '',
        sub_description: '',
        content: '',
        date: formatDate(new Date()), // Nouveau champ pour la date
        idAuteur: userId, // Nouveau champ pour l'ID de l'auteur
        sourcesName: '', // Nouveau champ pour le nom des sources
        hashtagName: '', // Nouveau champ pour le nom des hashtags
        image_path: ''
        // autres champs si nécessaire
    });
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const modules = {
    imageResize: {

    },
    toolbar: [
      [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
      [{ 'size': [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, 
       {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image', 'video'],
      [{ 'color': [] }, { 'background': [] }],
      ['clean']
    ],
  };
  const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video',
    'color', 'background'
  ];

  // Styles pour le modal
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50vw',
    height: '50vh',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflowY: 'auto', // Ajoute un scroll si nécessaire
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };

  // Fonction pour ouvrir le modal de la bibliothèque d'images
  const handleOpenImageLibrary = () => {
    setOpenImageLibrary(true);
  };

  // Fonction pour fermer le modal de la bibliothèque d'images
  const handleCloseImageLibrary = () => {
    setOpenImageLibrary(false);
  };

  const handleImageSelect = (image) => {
    setSelectedImage(image); // Stocke l'image sélectionnée
    console.log("Selected image:", image);
    handleCloseImageLibrary(); // Ferme le modal après la sélection
  };

  // Mettre à jour l'état du article lors des changements dans le formulaire
  const handleChange = (e) => {
    setArticle({ ...article, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    // Effectuer une requête pour récupérer les sources existantes
    const fetchSources = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_ADMIN_URL}/sources`, { withCredentials: true }); // Remplacez par votre URL
        setExistingSources(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des sources', error);
      }
    };

        fetchSources();
    }, []);

    useEffect(() => {
        // Effectuer une requête pour récupérer les hashtags existants
        const fetchHashtags = async () => {
          try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_ADMIN_URL}/hashtags`, { withCredentials: true }); // Remplacez par votre URL
            setExistingHashtags(response.data);
          } catch (error) {
            console.error('Erreur lors de la récupération des hashtags', error);
          }
        };
    
        fetchHashtags();
      }, []);

      useEffect(() => {
        const fetchImages = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_ADMIN_URL}/images`, { withCredentials: true });
                setImages(response.data); // Mettez à jour l'état avec les images chargées
            } catch (error) {
                console.error('Erreur lors de la récupération des images:', error);
            }
        };
    
        fetchImages();
      }, []);

      useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/articles/articles/${articleId}`, { withCredentials: true })
            .then(response => {
                const articleData = response.data;
                setArticle({
                  titre: articleData.titre || '',
                  sub_description: articleData.sub_description || '',
                  content: articleData.content || '',
                  image_path: articleData.image_path || '',
                });
                if (articleData.sources) {
                  setSelectedSources(articleData.sources.map(source => source.id));
                }
                if (articleData.hashtags) {
                  setSelectedHashtag(articleData.hashtags.map(hashtag => hashtag.id));
                }
                if (articleData.image_path) {
                  setImagePreviewUrl(`${process.env.REACT_APP_BACKEND_URL}/${articleData.image_path}`);
                }
                setIsLoading(false); // Mettre à jour l'état de chargement
            })
            .catch(error => {
                console.error('Erreur lors du chargement de l\'article:', error);
                setIsLoading(false); // Mettre à jour l'état de chargement même en cas d'erreur
            });
      }, [articleId]);
      
      if (isLoading) {
          return <div>Chargement en cours...</div>;
      }

      const handleSubmit = async (e) => {
        e.preventDefault();

        // Préparer les identifiants de sources et hashtags sélectionnés
        const sourceIds = Array.isArray(selectedSources) ? selectedSources.filter(source => source !== 'new') : [];
        const hashtagIds = Array.isArray(selectedHashtag) ? selectedHashtag.filter(hashtag => hashtag !== 'new') : [];
    
        const formData = new FormData();
        formData.append('titre', article.titre);
        formData.append('sub_description', article.sub_description);
        formData.append('content', article.content);
        sourceIds.forEach((id, index) => {
          formData.append(`sourceIds[${index}]`, id);
        });
        formData.append('newSourceName', selectedSources.includes('new') ? newSourceName : '');
        hashtagIds.forEach((id, index) => {
            formData.append(`hashtagIds[${index}]`, id);
        });
        formData.append('newHashtagName', selectedHashtag.includes('new') ? newHashtagName : '');
    
        if (selectedImage) {
          formData.append('imagePath', selectedImage.image_path);
        } else if (!selectedImage && article.image_path) {
          formData.append('imagePath', article.image_path);
        }
        try {
            await axios.put(
                `${process.env.REACT_APP_BACKEND_ADMIN_URL}/articles/edit/${articleId}`,
                formData,
                {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );
            setOpenSnackbar(true);
        } catch (error) {
            console.error('Erreur lors de la mise à jour de l\'article:', error);
        }
    };

  // Gestion des changements de sources
  const handleSourceChange = (event) => {
    setSelectedSources(event.target.value);
    setShowNewSourceInput(event.target.value.includes('new'));
    };

    const handleAddNewSource = async () => {
        if (newSourceName) {
            try {
                // Envoyer la nouvelle source au backend
                const response = await axios.post(
                    `${process.env.REACT_APP_BACKEND_ADMIN_URL}/sources`, 
                    { name: newSourceName },
                    { withCredentials: true }
                );

                // Ajouter la nouvelle source à existingSources et selectedSources
                const newSource = response.data;
                setExistingSources(prevSources => [...prevSources, newSource]);
                setSelectedSources(prevSelectedSources => [...prevSelectedSources, newSource.id]);

                // Réinitialiser newSourceName
                setNewSourceName('');
            } catch (error) {
                console.error('Erreur lors de l\'ajout de la nouvelle source:', error);
            }
            setShowNewSourceInput(false);
        }
    };

    // Supprimer une source sélectionnée
    const handleDeleteSource = (sourceToDelete) => {
        setSelectedSources((sources) => sources.filter((source) => source !== sourceToDelete));
    };

    const handleHashtagChange = (event) => {
      setSelectedHashtag(event.target.value);
      setShowNewHashtagInput(event.target.value.includes('new'));
    };

    const handleAddNewHashtag = async () => {
      if (newHashtagName) {
        try {
          // Envoyer la nouvelle source au backend
          const response = await axios.post(
              `${process.env.REACT_APP_BACKEND_ADMIN_URL}/hashtags`, 
              { name: newHashtagName },
              { withCredentials: true }
          );

          // Ajouter du nouveau hashtag à setExistingHashtags et setSelectedHashtag
          const newHashtag = response.data;
          setExistingHashtags(prevHashtags => [...prevHashtags, newHashtag]);
          setSelectedHashtag(prevSelectedHashtag => [...prevSelectedHashtag, newHashtag.id]);

          // Réinitialiser NewHashtagName
          setNewHashtagName('');
        } catch (error) {
            console.error('Erreur lors de l\'ajout du nouveau hashtag:', error);
        }
        setShowNewHashtagInput(false);
      }
    };
    
  const handleDeleteHashtag = (hashtagToDelete) => {
    setSelectedHashtag((hashtags) => hashtags.filter((hashtag) => hashtag !== hashtagToDelete));
  };

  const handleEditorChange = (content) => {
      setArticle({ ...article, content });
  };

  return (
    <form onSubmit={handleSubmit}>
        <div className='textfield'>
            <TextField 
            label="Titre" 
            name="titre" 
            value={article.titre} 
            onChange={handleChange}
            variant="filled" 
            color="secondary"
            size='medium'
            margin="normal"
            fullWidth
            required
            />
            <div className='others'>
                <div className='ss-description'>
                    {/* Champ pour la sous-description */}
                    <TextField 
                        label="Sous-description" 
                        name="sub_description" 
                        value={article.sub_description} 
                        onChange={handleChange}
                        variant="filled" 
                        color="secondary"
                        size='medium'
                        margin="normal"
                        fullWidth
                        required
                    />
                </div>
                {/* Champ pour la date */}
                <input 
                    type="hidden"
                    name="date"
                    value={article.date}
                />

                {/* Champ pour l'ID de l'auteur */}
                <input 
                    type="hidden"
                    name="idAuteur"
                    value={article.idAuteur}
                />

                <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap', flexDirection: 'column' }}>
                  {/* Champ de sélection pour les sources */}
                  <FormControl margin="normal">
                      <Select
                          labelId="multiple-source-select-label"
                          multiple
                          value={selectedSources}
                          onChange={handleSourceChange}
                          style={{ minWidth: '100px' }}
                          displayEmpty
                          renderValue={() => "Choisir une source"}
                      >
                          {existingSources.map((source) => (
                              <MenuItem key={source.id} value={source.id}>
                                  {source.name}
                              </MenuItem>
                          ))}
                          <MenuItem value="new">Ajouter une nouvelle source</MenuItem>
                      </Select>
                  </FormControl>

                  {/* Affichage des jetons pour les sources sélectionnées */}
                  <div>
                    {selectedSources.filter(source => source !== 'new').map((source) => (
                        <Chip
                            key={source}
                            label={existingSources.find(s => s.id === source)?.name}
                            onDelete={() => handleDeleteSource(source)}
                            style={{ margin: '2px' }}
                        />
                    ))}
                  </div>
                </div>

                {/* Champ pour ajouter une nouvelle source */}
                {showNewSourceInput && (
                    <TextField 
                      label="Nouvelle Source" 
                      value={newSourceName}
                      onChange={(e) => setNewSourceName(e.target.value)}
                      variant="filled" 
                      color="secondary"
                      margin="normal"
                      style={{ flex: 0.5, width: '100px' }} // Réduire la largeur à 50% par exemple
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleAddNewSource}>
                              <AddCircleOutlineIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                )}

                <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap', flexDirection: 'column' }}>
                  {/* Liste déroulante pour les hashtags */}
                  <FormControl margin="normal">
                      <Select
                      labelId="multiple-hashtag-select-label"
                      multiple
                      value={selectedHashtag}
                      onChange={handleHashtagChange}
                      style={{ minWidth: '100px' }}
                      displayEmpty
                      renderValue={() => "Choisir un Hashtag"}
                      >
                      {existingHashtags.map((hashtag) => (
                          <MenuItem key={hashtag.id} value={hashtag.id}>
                            {hashtag.name}
                          </MenuItem>
                      ))}
                      <MenuItem value="new">Ajouter un nouveau hashtag</MenuItem>
                      </Select>
                  </FormControl>

                  {/* Affichage des jetons pour les hashtags sélectionnés */}
                  <div>
                      {selectedHashtag.filter(hashtag => hashtag !== 'new').map((hashtag) => (
                          <Chip
                              key={hashtag}
                              label={existingHashtags.find(h => h.id === hashtag)?.name}
                              onDelete={() => handleDeleteHashtag(hashtag)}
                              style={{ margin: '2px' }}
                          />
                      ))}
                  </div>
                </div>

                {/* Champ pour ajouter un nouveau hashtag */}
                {showNewHashtagInput && (
                    <TextField 
                    label="Nouveau Hashtag" 
                    value={newHashtagName}
                    onChange={(e) => setNewHashtagName(e.target.value)}
                    variant="filled" 
                    color="secondary"
                    margin="normal"
                    style={{ flex: 0.5, width: '100px' }}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                          <InputAdornment position="end">
                              <IconButton onClick={handleAddNewHashtag}>
                                  <AddCircleOutlineIcon />
                              </IconButton>
                          </InputAdornment>
                      ),
                  }}
                    />
                )}

            </div>  

            <div className='others'>
                <Button variant="contained" onClick={handleOpenImageLibrary}>Sélectionner depuis la bibliothèque</Button>
            </div>
            <Modal
                open={openImageLibrary}
                onClose={handleCloseImageLibrary}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                  <IconButton
                      aria-label="close"
                      onClick={handleCloseImageLibrary}
                      sx={{
                          position: 'absolute',
                          right: 8,
                          top: 8,
                      }}
                  >
                  <CloseIcon />
                  </IconButton>
                  <Typography variant="h6" sx={{ mb: 2 }}>Sélectionnez une image</Typography>
                  <Grid container spacing={2}>
                      {images.map((image) => (
                          <Grid item xs={4} key={image.id} onClick={() => handleImageSelect(image)}>
                              <Card>
                                  <CardMedia
                                      component="img"
                                      height="140"
                                      crossOrigin="anonymous"
                                      image={`${process.env.REACT_APP_BACKEND_URL}/${image.image_path}`}
                                      alt="Image"
                                  />
                              </Card>
                          </Grid>
                      ))}
                  </Grid>
              </Box>
            </Modal>
            {/* Afficher l'image sélectionnée (facultatif) */}
            {selectedImage ? (
                <Box mt={2}>
                    <Typography>Image sélectionnée :</Typography>
                    {/* Assurez-vous que `selectedImage` contient bien le chemin d'accès à l'image, ou ajustez selon la structure de vos données */}
                    <img src={`${process.env.REACT_APP_BACKEND_URL}/${selectedImage.image_path}`} crossOrigin="anonymous" alt="Selected" style={{ width: '100px', height: 'auto' }} />
                </Box>
            ) : imagePreviewUrl && (
                <Box mt={2}>
                    <Typography>Image actuelle :</Typography>
                    <img src={imagePreviewUrl} crossOrigin="anonymous" alt="Aperçu de l'article" style={{ width: '100px', height: '100px' }} />
                </Box>
            )}
        </div>
        
        {/* Autres champs du formulaire */}
        <div className="form-container">
            <ReactQuill 
                className="react-quill-container"
                value={article.content}
                onChange={handleEditorChange}
                modules={modules}
                formats={formats} 
            />
        </div>

        <div className='submit-button'>
            <Button variant="contained" color="secondary" type="submit">Enregistrer</Button>
        </div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity="success" sx={{ width: '100%' }}>
          Article Modifié avec Succès
        </Alert>
      </Snackbar>
    </form>
  );
};

export default ArticleEditForm;
